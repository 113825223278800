<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog">
      <div class="edit-modal-content">
        <div class="modal-header header_modal_icon">
          <h5 class="modal-title" style="color: red;"><img :src="icon_info_warning" class="icon_info_warning" />&nbsp;{{ title }}</h5>
          <img :src="cross" @click="onClose" class="cross cursor-pointer" />
        </div>
        <div class="modal-body" v-if="type===null">
          <ul class="message-wrapper">
            <!-- Render the error messages passed as props -->
            <li v-for="(message, key) in messages" :key="key" class="edit-error-message text_24">
              {{ message }}
            </li>
          </ul>
        </div>
        <div class="modal-body" v-else>
          <div class="message-wrapper">
            <!-- Render the error messages passed as props -->
            <div v-html="messages[0]" style="font-size:24px;text-align: center;color: var(--color-metal-grey);">
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="type===null">
          <button type="button" @click="onClose" class="btn btn-primary">確認</button>
        </div>
        <div class="modal-footer" v-else>
          <button type="button" @click="onClose" class="btn">キャンセル</button>
          <button type="button" @click="save" class="btn btn-primary">登録する</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import cross from "../../../../assets/images/black-cross.svg";
import icon_info_warning from "../../../../assets/images/icon_timetable/icon_info_warning.svg";

export default {
  props: {
    type:{
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true,
    },
    messages: {
      type: Array,
      required: true, // Expect an array of messages
    },
  },
  data() {
    return {
      cross: cross,
      icon_info_warning: icon_info_warning,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    save(){
      this.$emit("save");
    }
  },
};
</script>

<style scoped>
.modal-dialog {
  z-index: 2007;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
.modal.backdrop{
  z-index: 2006;
}
.modal{
  z-index:2006
}
.message-wrapper{
  width: 100%;
  text-align: left;
  padding-top: 16px;
}
.cross {
  width: 20px;
  color: black;
  margin-left: auto;
}
.modal-header{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
}
.edit-modal-content{
  min-height: calc(100vh - 200px);
  border-radius: 12px;
}
.modal-title{
  margin-left: auto;
}
@media only screen
and (min-device-width: 768px){
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }
  .edit-modal-content{
    min-height: calc(100vh - 400px);
  }
  .edit-error-message{
    font-size: 18px;
    color: var(--color-main);
  }
}
.modal-header {
  border-bottom: none;
}
.edit-modal-content {
  min-height: calc(100vh - 600px);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
</style>
