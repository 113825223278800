<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="time">{{ formatTime(reservation.start_time) }}~{{ formatTime(reservation.end_time) }}  &nbsp; {{ reservation.number_of_people }}名</div>
        <div class="customer-name row">{{reservation.last_name}} {{reservation.first_name}} 様</div>
      </div>

      <!-- Modal Body for edit -->
      <div class="modal-body">
        <div class="counter">
          <div class="label">大人</div>
          <div class="counter-controls">
            <button @click="decreaseAdults" class="btn-counter"><i class="fa fa-minus" aria-hidden="true"></i></button>
            <input type="text" v-model="adults" class="button counter-input" />
            <button @click="increaseAdults" class="btn-counter"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </div>

        <div class="counter">
          <div class="label">子供</div>
          <div class="counter-controls">
            <button @click="decreaseChildren" class="btn-counter"><i class="fa fa-minus" aria-hidden="true"></i></button>
            <input type="text" v-model="children" class="button counter-input" />
            <button @click="increaseChildren" class="btn-counter"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer-peoples">
        <button class="btn-cancel" @click="cancel">キャンセル</button>
        <button class="btn-confirm" @click="confirm">確定</button>
      </div>
    </div>

    <!-- Validation Modal -->
    <EditValidationModal
        v-if="showValidationModal"
        :title="'確認'"
        :messages="validationErrorMessages"
        @close="showValidationModal = false"
    />
  </div>
</template>

<script>
import Http from "../../../shared/http";
import stores from "../../../src/stores";
import { validationMessages } from "../../../src/validator/reservationValidationMessages";
import { decode_data } from "../../../shared/util";
import EditValidationModal from "../../../src/components/EditValidationModal.vue";

export default {
  components: {
    EditValidationModal,
  },
  props: {
    reservation: Object,
  },
  data() {
    return {
      adults: this.reservation.number_of_adults || 0,
      children: this.reservation.number_of_children || 0,
      validationErrorMessages: [], // Will hold error messages
      showValidationModal: false, // Controls modal visibility
      shop_tables: decode_data("#data-shop-tables"),
      reservation_staffs: decode_data("#data-reservation-staffs"),
    };
  },
  computed: {
    activeReservation() {
      return stores.getters.getActiveReservation;
    },
  },
  methods: {
    formatTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    decreaseAdults() {
      if (this.adults > 0) this.adults--;
    },
    increaseAdults() {
      this.adults++;
    },
    decreaseChildren() {
      if (this.children > 0) this.children--;
    },
    increaseChildren() {
      this.children++;
    },
    cancel() {
      this.$emit("close");
    },
    hasMorePeople() {
      const selectedSeats = this.reservation.shop_table_ids || [];
      const tables = this.shop_tables.filter(table => selectedSeats.includes(table.id));
      const totalPeople = this.adults + this.children;

      let maxPeopleAllowed = 0;
      tables.forEach(table => {
        maxPeopleAllowed += table.number_of_people_max;
      });

      return totalPeople > maxPeopleAllowed;
    },
    async confirm() {
      this.validationErrorMessages = [];
      if (this.adults === 0) {
        this.validationErrorMessages.push(validationMessages.MINIMUM_ADULTS); // Add validation message
        this.showValidationModal = true; // Show validation modal
        return;
      }
      if (this.hasMorePeople()) {
        this.validationErrorMessages.push(validationMessages.RESERVATION_EXCEEDED); // Add validation message
        this.showValidationModal = true; // Show validation modal
        return;
      }

      this.$emit("update:number_of_adults", this.adults);
      this.$emit("update:number_of_children", this.children);
      this.$emit("update:number_of_people", this.adults + this.children);
      await this.save_reservation();

      const date = new Date(this.reservation.start_time);
      const formattedDate = date.toISOString().split("T")[0];
      const slug = document.getElementById("slug").value == "" ? "" : `/${document.getElementById("slug").value}`;
      window.location.href = `${slug}/shop_manager/time_table?date=${formattedDate}`;
    },
    async save_reservation() {
      const slug = document.getElementById("slug").value == "" ? "" : `/${document.getElementById("slug").value}`;
      const path = `${slug}/shop_manager/time_table/${this.reservation.id}`;

      await Http.post(path, {
        reservation: {
          number_of_adults: this.adults,
          number_of_children: this.children,
          number_of_people: this.adults + this.children,
        },
      })
          .then(response => {
            this.$emit("reload");
            this.$emit("close");
          })
          .catch(error => {
            this.$emit("loading", false);
            this.errors = error.response.data;
          });
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  align-items: flex-end;
  padding: 1rem;
}

.modal-container {
  background-color: white;
  border-radius: 15px;
  width: 320px;
  padding: 0 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
}

.time {
  font-size: 16px;
  color: var(--color-metal-grey);
  margin-left: -15px;
}

.customer-name {
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  color: var(--color-metal-grey);
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 -14px;
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: 16px;
  color: var(--color-metal-grey);
}

.counter-controls {
  display: flex;
  align-items: center;
}

.btn-counter {
  width: 40px;
  height: 40px;
  background-color: var(--color-text-reservation);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.counter-input {
  width: 5rem;
  font-size: 18px;
  padding: 0.2rem 0.5rem;
  margin: 0 1rem;
  border-radius: 4px !important;
  color: var(--color-metal-grey) !important;
  border: 1px solid var(--color-text-reservation) !important;
}

.modal-footer-peoples {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.btn-cancel, .btn-confirm {
  width: 45%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn-cancel {
  background-color: #fff;
  border: 1px solid #CCCCCC;
  color: #999999;
}

.btn-confirm {
  background-color: var(--color-text-reservation);
  color: white;
}
</style>