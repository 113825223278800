import { render, staticRenderFns } from "./CustomerModal.vue?vue&type=template&id=501fc66c&scoped=true&"
import script from "./CustomerModal.vue?vue&type=script&lang=js&"
export * from "./CustomerModal.vue?vue&type=script&lang=js&"
import style0 from "./CustomerModal.vue?vue&type=style&index=0&id=501fc66c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501fc66c",
  null
  
)

export default component.exports