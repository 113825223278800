import Vue from "vue/dist/vue.esm";
import VueSweetalert2 from 'vue-sweetalert2';
import router from "../../src/routes";
import store from "../../src/stores";
import VueRouter from "vue-router";
import TimeTableParent from "./index.vue";
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.use(VueRouter);
document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("shop-manager-time-table")) {
    new Vue({
      el: "#shop-manager-time-table",
      components: { TimeTableParent },
      render: (h) => h(TimeTableParent),
      store,
    });
  }
});
