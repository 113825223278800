<template>
  <div>
    <div class="main-color">予約タグ</div>
    <div class="grid-container">
      <div v-for="(tag, index) in tags" :key="index" class="tag-main-container p-2 mt-2 input-field-font" @click="toggleSelection(tag)"
           :class="{ 'selected': tags_id.includes(tag.id) }" v-if="tag.is_default" :title="tag.name">
        <div v-if="tag.icon === 'crown'" class="tag-images text-center ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="tag-images p-1">
            <g id="グループ_400" data-name="グループ 400" transform="translate(530 -3683)">
              <path id="パス_114" data-name="パス 114" d="M7.211,32.291a7.113,7.113,0,0,0,.737-.074l3.8,42.791H128.257l3.8-42.791a7.118,7.118,0,0,0,.736.074,7.221,7.221,0,1,0-5.068-2.087l-26.3,26.3L72.628,13.911a7.212,7.212,0,1,0-5.256,0L38.577,56.5l-26.3-26.3a7.207,7.207,0,1,0-5.068,2.087" transform="translate(-520 3712)" fill="#ffffff"/>
              <path id="パス_115" data-name="パス 115" d="M24.675,166.285l1.485,16.742H137.886l1.485-16.742Z" transform="translate(-532.023 3630.978)" fill="#ffffff"/>
              <rect id="長方形_653" data-name="長方形 653" width="30" height="30" transform="translate(-530 3683)" fill="none"/>
            </g>
          </svg>
          <span class="tag-svg-text">{{ tag.name }}</span>
        </div>
        <div v-else-if="tag.icon === 'coupon'" class="tag-images text-center ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="tag-images p-1">
            <g id="グループ_402" data-name="グループ 402" transform="translate(733 -3404)">
              <path id="前面オブジェクトで型抜き_1" data-name="前面オブジェクトで型抜き 1" d="M128.175,81.452H11.824A11.825,11.825,0,0,1,0,69.629V52.98a12.4,12.4,0,0,0,7.564-4.108,12.356,12.356,0,0,0,3.061-8.145,12.357,12.357,0,0,0-3.061-8.146A12.41,12.41,0,0,0,0,28.472V11.825A11.824,11.824,0,0,1,11.824,0H128.175A11.825,11.825,0,0,1,140,11.825V28.361a12.378,12.378,0,0,0,0,24.729V69.629a11.825,11.825,0,0,1-11.824,11.823ZM29.159,63.381v10.61h5.068V63.381Zm30.6-38.735a12.682,12.682,0,0,0-5.37,1.138,11.923,11.923,0,0,0-4.17,3.227c-2.395,2.874-3.66,6.884-3.66,11.6,0,4.562,1.2,8.45,3.475,11.244a11.613,11.613,0,0,0,9.325,4.315,12.082,12.082,0,0,0,9.37-4.03c2.48-2.816,3.79-6.969,3.79-12.01a21.252,21.252,0,0,0-.852-6.131,14.818,14.818,0,0,0-2.483-4.909A11.545,11.545,0,0,0,59.76,24.646Zm38.64.481v30.6h4.64V42.286h10.48V38.446H103.04v-9.44h11.28v-3.88Zm-21.119,0v30.6H81.92V42.286H92.4V38.446H81.92v-9.44H93.2v-3.88ZM29.159,44.741v10.61h5.068V44.741Zm0-18.643V36.711h5.068V26.1Zm0-18.637V18.07h5.068V7.461Zm30.361,44.9A6.924,6.924,0,0,1,53.5,48.751a15.929,15.929,0,0,1-2.06-8.265c0-5.993,2.523-12.04,8.159-12.04a6.9,6.9,0,0,1,6.075,3.73,16.135,16.135,0,0,1,1.965,8.11,16.457,16.457,0,0,1-2.05,8.485A6.936,6.936,0,0,1,59.52,52.366Z" transform="translate(-724 3443)" fill="#ffffff"/>
              <rect id="長方形_655" data-name="長方形 655" width="16" height="16" transform="translate(-733 3404)" fill="none"/>
            </g>
          </svg>
          <span class="tag-svg-text">{{ tag.name }}</span>
        </div>
        <div v-else-if="tag.icon === 'cheers'" class="tag-images text-center ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="tag-images p-1">
            <g id="グループ_401" data-name="グループ 401" transform="translate(447 -3404)">
              <path id="パス_116" data-name="パス 116" d="M60.682,61,23.425,51.782a3.883,3.883,0,0,0-4.707,2.841l-13.1,52.925a23.064,23.064,0,0,0,13.165,26.67l-6.662,26.924-7.3-1.808a3.89,3.89,0,0,0-1.869,7.552l22.159,5.483a3.888,3.888,0,0,0,1.869-7.548l-7.309-1.81,6.663-26.926a23.067,23.067,0,0,0,24.09-17.45l13.1-52.926A3.889,3.889,0,0,0,60.682,61M50.371,86.488,20.664,79.137l4.669-18.871,29.707,7.349Z" transform="translate(-435.755 3381.518)" fill="#ffffff"/>
              <path id="パス_117" data-name="パス 117" d="M262.463,162.176a3.891,3.891,0,0,0-4.709-2.843l-7.3,1.808-6.661-26.924a23.067,23.067,0,0,0,13.167-26.67l-13.1-52.925a3.883,3.883,0,0,0-4.707-2.841L201.9,61a3.889,3.889,0,0,0-2.841,4.709l13.1,52.926a23.067,23.067,0,0,0,24.09,17.45L242.9,163.01l-7.309,1.81a3.888,3.888,0,0,0,1.869,7.548l22.159-5.483a3.889,3.889,0,0,0,2.839-4.709M212.207,86.488l-4.669-18.873,29.707-7.349,4.669,18.871Z" transform="translate(-560.823 3381.518)" fill="#ffffff"/>
              <path id="パス_118" data-name="パス 118" d="M180.219,0A2.917,2.917,0,0,0,177.3,2.916V9.234a2.916,2.916,0,1,0,5.833,0V2.916A2.917,2.917,0,0,0,180.219,0" transform="translate(-547.219 3414)" fill="#ffffff"/>
              <path id="パス_119" data-name="パス 119" d="M125.994,21.64a2.916,2.916,0,1,0,4.671-3.492l-4.322-5.78a2.917,2.917,0,0,0-4.671,3.494Z" transform="translate(-511.881 3406.96)" fill="#ffffff"/>
              <path id="パス_120" data-name="パス 120" d="M227.127,21.64l4.322-5.779a2.916,2.916,0,0,0-4.671-3.494l-4.322,5.78a2.916,2.916,0,1,0,4.671,3.492" transform="translate(-575.24 3406.96)" fill="#ffffff"/>
              <rect id="長方形_654" data-name="長方形 654" width="30" height="30" transform="translate(-447 3404)" fill="none"/>
            </g>
          </svg>
          <span class="tag-svg-text">{{ tag.name }}</span>
        </div>
        <div v-else class="tag-images text-center  ml-2">
          <i :class="[tag.icon, 'pe-auto']" style="padding-top: 0.4rem" aria-hidden="true"></i><span class="pl-2 tag-icon-text">{{ tag.name }}</span>
        </div>
      </div>

    </div>
    <div class="mt_small position-relative">
      <v-select
          v-model="dataSelect"
          :options="tags.filter(tag => !tag.is_default)"
          label="name"
          :clearable="false"
          :multiple="true"
          :searchable="false"
          class="form_select_table"
          placeholder="他のタグ"
          ref="selectTag"
          :reduce="o => o.id"
          @click.native.stop="forceOpen"
          :dropdown-should-open="shouldOpenDropdown"
      >
        <template #no-options>
          追加タグはありません
        </template>
        <template slot="option" slot-scope="option">
          <input
              type="checkbox"
              :checked="isChecked(option.id)"
              @click.stop="dropDownToggleSelection(option)"
          />
          <span> {{ option.name }}</span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import Http from "../../shared/http";

import vSelect from 'vue-select'

export default {
  components: {vSelect},
  props: {
    clearTag: {
      type: Boolean
    },
    selectedTags:{
      type: Array
    },
    reservation:{
      type:Object
    }
  },

  data() {
    return {
      tags: [],
      date: '',
      tags_id: [],
      dataSelect: [],
      dropDownSelection: false
    };
  },
  mounted() {
    this.getUpdateData()
    document.addEventListener('click', this.checkOutsideClick);
    this.getStoreTags();
  },

  watch: {
    dataSelect: {
      handler() {
        this.combinedToggleSelection()
      }
    },
    tags_id: {
      handler() {
        this.combinedToggleSelection()
      }
    },
    reservation: {
      handler() {
        this.clearSelectedTags()
        this.getUpdateData()
      },
    },
    deep: true
  },
  methods: {
    isChecked(tagId) {
      return this.dataSelect.includes(tagId);
    },
    getUpdateData(){
      if(this.selectedTags){
        this.selectedTags.forEach(selectedTag => {
          if(!selectedTag?.is_default && selectedTag?.id) {
            this.dataSelect.push(selectedTag.id)
          }
          if(selectedTag?.is_default && selectedTag?.id) {
            this.tags_id.push(selectedTag.id)
          }
        });
      }
    },


    dropDownToggleSelection(option) {
      if (this.dataSelect.includes(option.id)) {
        this.dataSelect = this.dataSelect.filter(id => id !== option.id);
      } else {
        this.dataSelect.push(option.id);

      }

    },
    clearSelectedTags() {
      this.dataSelect = [];
      this.tags_id = [];
    },

    toggleSelection(tag) {
      const index = this.tags_id.indexOf(tag.id);
      if (index !== -1) {
        this.tags_id.splice(index, 1);
        tag.selected = false;
      } else {
        this.tags_id.push(tag.id);
        tag.selected = true;
      }
      this.combinedToggleSelection()
    },


    combinedToggleSelection() {
      const combineData = this.tags_id.concat(this.dataSelect);
      this.$emit('tags-updated', combineData);

    },
    async getStoreTags() {
      const slug = document.getElementById('slug').value || '';
      const path = `${slug}/shop_manager/tags/shop_tags`;
      try {
        const response = await Http.get(path, {date: this.date});
        this.tags = response.data.tags.map(tag => ({...tag, selected: false}));
      } catch (error) {
        console.log(error);
      }
    },
    forceOpen() {
      this.$refs.selectTag.isMenuActive = true;
      this.dropDownSelection = true
      this.combinedToggleSelection()
    },
    checkOutsideClick(event) {
      if (this.dropDownSelection && this.$refs.selectTag && !this.$refs.selectTag.$el.contains(event.target)) {
        this.outsideClick();
      }
    },
    outsideClick() {
      this.$refs.selectTag.isMenuActive = false;
      this.dropDownSelection = false
    },
    shouldOpenDropdown() {
      return this.dropDownSelection;
    },
  }
}
</script>


<style scoped>
.main-color {
  font-size:16px;
  color: var(--color-text-reservation);
}

.tag-main-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border: 1px solid var(--color-text-reservation);
  border-radius: 5px;
  width: 45%;
  display: inline-flex;
  gap: 3%;
  align-items: center;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8%;
}

.tag-main-container.selected {
  background-color: var(--color-tag-bg);
  border: 3px solid var(--color-reserved-border);
  svg{
    fill: var(--color-main);
}
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.selected-tag {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.remove-tag-icon {
  cursor: pointer;
  margin-left: 5px;
}

.form_select_table {
  --vs-font-size: 0;
  --vs-actions-padding: 8px 0px 8px 0px;
  --vs-controls-size: 0;
}

.form_select_table {
  width: 100%;
  position: relative;
}
.b-white{
  background-color: white;
}
.tag-svg{
  width: 2rem;
  height:2rem;
}
.tag-images{
  i, svg{
    background: var(--color-text-reservation);
    color: white;
    font-size: 16px !important;
    width: 35px;
    height: 30px;
    text-align: center;
  }
  span{
    color: var(--color-text-reservation);
  }
}
.tag-icon-text{
  position: relative;
  bottom: 0px;
  font-size: 16px;
  text-align: center;
}
.vs__search{
 font-size: 16px !important;
}
.vs__selected{
  font-size: 16px !important;
  text-align: center;
  padding: 6px
}
.tag-svg-text{
  position: relative;
  font-size: 16px !important;
  text-align: center;
  top: 0;
}
</style>