<template>
  <div class="modal fade show rounded-lg " style="display: block;">
    <div class="modal-dialog modal-dialog-scrollable new-memo-modal-size ">
      <div class=" new-memo-modal-content modal-content ">
        <div class="modal-header new-memo-modal-header">
          <div class="new-memo-modal-title">新規引き継ぎメモ</div>
          <button type="button" class="close close_reservation_modal close-block text-white" @click="onClose"><span>&times;</span></button>
        </div>

        <div class="modal-body pd-0">
          <p class="memo-modal-sub-header">引き継ぎ内容</p>
          <textarea class="form-control shop-memo " rows="5" v-model="content" maxlength="1000"></textarea>
        </div>

        <div class="modal-footer" style="border-top: 0;">
          <button type="button" class="btn btn-primary btn-size" @click="onSave">作成する</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    date: {
      type: Date,
    },
  },
  mounted() {
    this.content = ''
  },
  data() {
    return {
      content: '',
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSave() {
      if (!this.content) {
        this.$emit('close')
        return
      }
      const params = {
        date: moment(this.date).format('YYYY-MM-DD'),
        content: this.content,
      }
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.shop-memo {
  cursor: text;
  line-height: 1.6;
  font-size: 14px;
  max-height: 600px;
  &:disabled{
    background: #fff;
    border: none;
  }
}

.new-memo-modal-size{
  min-width: 660px;
}
@media (max-width: 767px) {
  .new-memo-modal-size {
    min-width: 50vw !important;
  }
}

.new-memo-modal-content{
  border-radius: 16px;
}

.new-memo-modal-header{
  background: var(--color-text-reservation);
  height: 46px;
  display: flex;
  align-items: center;
}

.new-memo-modal-title{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
}

.btn-size{
  width: 180px;
}

.memo-modal-sub-header{
  color: var(--color-table-name);
  font-size: 12px;
  font-weight: normal;
}

</style>
