<template>
  <div class="reservation-top-nav">
    <div class="top-nav">
      <span></span>
      <span> {{reservationType==='walk-in' ? 'ウォークイン登録': '予約登録'}}
        </span>
      <img :src="cross" @click="close" class="cross cursor-pointer" />
      <CloseValidationModal v-if="activeReservation.showCloseValidationModal"
                            @close="closeModal"
                            :title="'close'"
                            :messages="'close'"/>
    </div>
  </div>
</template>
<script>
import cross from "../../../../../assets/images/white-cross.svg"
import CloseValidationModal from "../../components/CloseValidationModal.vue";
import stores from "../../stores"
export default {
  components: {CloseValidationModal},
  props:{
    reservationType:{
      type:String
    }
  },
  computed:{
    activeReservation(){
      return stores.getters.getActiveReservation
    }
  },
  data(){
    return{
      cross:cross
    }
  },
  methods:{
    close(){
      // if(this.activeReservation.current_step!==1) {
        stores.commit('SET_SHOW_CLOSE_VALIDATION_MODAL', true)
      // }
      // else{
      //   window.location.href = `/shop_manager/time_table?date=${this.$route.query.date}`
      // }
    },
    closeModal(){
      stores.commit('SET_SHOW_CLOSE_VALIDATION_MODAL',false)
    },
    // close(){
    //
    //   window.location.href = `/shop_manager/time_table?date=${this.$route.query.date}`
    //   // this.$router.push(`/shop_manager/time_table?date=${this.$route.query.date}`)
    // }
      // this.$router.push(`/shop_manager/time_table?date=${this.$route.query.date}`)
    }

}
</script>
<style scoped>
.top-nav {
  padding: 2px 24px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  height: 5dvh; /* Corrected from 5dvh */
  font-size: 20px;
  color: white;
  background: var(--color-text-reservation);
}

.close-button {
  margin-right: 8px;
  cursor: pointer; /* Added cursor to indicate interactiveness */
}
.cross {
  width: 20px;
  color: white;
}
</style>