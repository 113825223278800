<template>
  <div class="reservation-modal">
    <div class="modal-content">
      <!------------------------   NEW DESIGN START ----------------------------------->
      <div class="heading">
        <span  class="title">予約詳細</span>
        <span @click="onClose" title="Close" class="title cross-button">x</span>
      </div>
      <div class="modal-body pd-0" id="modal-body">
        <div class="tab-div">
          <template v-if="isCreateNew">
            <div class="tab_area">
              <a class="tab order_manage btn_point cursor-pointer"
                 :class="mode === 'walkin' ? 'active' : ''"
                 @click="mode = 'walkin'"

              >ウォークイン登録</a>
              <a class="tab order_manage btn_point cursor-pointer"
                 :class="mode === 'normal' ? 'active' : ''"
                 @click="mode = 'normal'"
              >予約登録</a>
            </div>
          </template>

          <template v-else>
            <div class="tab_area">
              <a class="tab order_manage btn_point cursor-pointer"
                 :class="tab === 'reservation' ? 'active' : ''"
                 @click="tab = 'reservation'"
              >予約登録</a>
              <a v-if="reservation.customer_id"
                 class="tab order_manage btn_point cursor-pointer"
                 :class="tab === 'customer' ? 'active' : ''"
                 @click="tab = 'customer'"
              >顧客情報</a>
              <a v-if="reservation.pos_order_data"
                 class="tab order_manage btn_point cursor-pointer"
                 :class="tab === 'pos_order' ? 'active' : ''"
                 @click="tab = 'pos_order'"
              >喫食情報</a>
              <a v-if="reservation.ses_import_data != null"
                 class="tab order_manage btn_point cursor-pointer"
                 :class="tab === 'reservation_email' ? 'active' : ''"
                 @click="tab = 'reservation_email'"
              >予約メール</a>
            </div>
          </template>

        </div>
        <div v-if="tab!=='customer'" class="content-and-footer-container">
          <div class="content-div modal-container1">
            <div v-if="tab === 'reservation'" class="form-modal-reservation">
              <!------------ NEW DESIGN -------------------->
              <template v-if="mode==='walkin' && isCreateNew">
                <!--FIRST TAB-->
                <div class="form-modal-reservation">
                  <div class="row mt_small">
                    <v-select
                        v-model="shop_table_ids"
                        :options="shop_tables"
                        :reduce="o => o.id"
                        label="full_name"
                        placeholder="テーブル名"
                        :clearable="false"
                        :searchable="false"
                        :multiple="true"
                        class="form_select_table"
                        ref="selectMenu"
                        @click.native.stop="forceOpen"
                        :dropdown-should-open="() => dropDownSelection"
                    >
                      <template slot="option" slot-scope="option">
                        <input
                            type="checkbox"
                            :checked="isChecked(option.id)"
                            @click.stop="toggleSelection(option)"
                        />
                        {{ option.name }}
                        <span
                            class="second_span">[{{ option.number_of_people_min }}-{{
                            option.number_of_people_max
                          }}人]</span>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <span class="single_line first_span">
                          {{ option.name }}
                        </span>
                        <span
                            class="second_span">[{{ option.number_of_people_min }}-{{
                            option.number_of_people_max
                          }}人]</span>
                      </template>
                    </v-select>
                  </div>
                  <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
                  <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>

                  <div>
                    <div class="row mt_small select-with-label-row">
                      <div class="select-with-label-inside">
                        <span class="label-inside-select">大人：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_adults" @change="changeNumberPeople">
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>

                      <div class="select-with-label-inside">
                        <span class="label-inside-select">子供：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_children"
                                @change="changeNumberPeople">
                          <option :value="0"></option>
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>

                    </div>
                    <small v-for="error in errors['number_of_people']" :key="error" class="error">{{ error }}</small>
                  </div>

                  <div class="row mt_small">
                    <DatePicker
                        input-class="form-control input-field-font cursor-pointer"
                        v-model="start_time"
                        value-type="format"
                        format="HH:mm"
                        placeholder="予約開始時分"
                        type="time"
                        :show-second="false"
                        :minute-step="15"
                        :clearable="false">
                      <i slot="icon-calendar"></i>
                    </DatePicker>
                    <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <small v-for="error in errors['course_ids']" :key="error" class="error">{{ error }}</small>
                  <div class="row mt_small select-with-label-inside">
                    <span class="label-inside-select single-select-label">滞在分：</span>
                    <select class="form-control input-field-font customer_select cursor-pointer" v-model="stay_minutes">
                      <option v-if="!(new Array(16).fill(0).map((_, i) => i * 15 + 15)).includes(stay_minutes_option)"
                              :value="stay_minutes_option" style="display: none;">{{ stay_minutes_option }}
                      </option>
                      <option v-for="item in new Array(16).fill(0).map((_, i) => i * 15 + 15)" :value="item" :key="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <small v-for="error in errors['stay_minutes']" :key="error" class="error">{{ error }}</small>
                </div>

              </template>

              <!--SECOND TAB -->
              <template v-if="mode === 'normal' && isCreateNew">
                <!------------------------   NEW DESIGN START ----------------------------------->
                <div class="form-modal-reservation">

                  <div class="row mt_small select-with-label-row without-label">
                    <div class="select-with-label-inside">
                      <DatePicker
                          input-class="form-control input-field-font cursor-pointer"
                          v-model="start_date"
                          value-type="format"
                          format="YYYY-MM-DD"
                          :clearable="false">
                        <i slot="icon-calendar"></i>
                      </DatePicker>
                    </div>

                    <div class="select-with-label-inside">
                      <DatePicker
                          input-class="form-control input-field-font cursor-pointer"
                          v-model="start_time"
                          placeholder="予約開始時分"
                          value-type="format"
                          format="HH:mm"
                          type="time"
                          :show-second="false"
                          :minute-step="15"
                          :clearable="false">
                        <i slot="icon-calendar"></i>
                      </DatePicker>
                      <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>
                    </div>

                  </div>

                  <div>
                    <div class="row mt_small select-with-label-row">
                      <div class="select-with-label-inside">
                        <span class="label-inside-select">大人：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_adults" @change="changeNumberPeople">
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>

                      <div class="select-with-label-inside">
                        <span class="label-inside-select">子供：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_children"
                                @change="changeNumberPeople">
                          <option :value="0"></option>
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <small class="error" v-for="error in errors['number_of_people']" :key="error">{{error}}</small>
                  </div>


                  <div class="row mt_small">
                    <v-select
                        v-model="shop_table_ids"
                        :options="shop_tables"
                        :reduce="o => o.id"
                        label="full_name"
                        :clearable="false"
                        :searchable="false"
                        :multiple="true"
                        class="form_select_table"
                        ref="selectMenu"
                        placeholder =  "テーブル名"
                        @click.native.stop="forceOpen"
                        :dropdown-should-open="() => dropDownSelection"
                    >
                      <template slot="option" slot-scope="option">
                        <input
                            type="checkbox"
                            :checked="isChecked(option.id)"
                            @click.stop="toggleSelection(option)"
                        />
                        {{ option.name }}
                        <span
                            class="second_span">[{{ option.number_of_people_min }}-{{
                            option.number_of_people_max
                          }}人]</span>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <span class="single_line first_span">
                          {{ option.name }}
                        </span>
                        <span
                            class="second_span">[{{ option.number_of_people_min }}-{{
                            option.number_of_people_max
                          }}人]</span>
                      </template>
                    </v-select>
                    <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
<!--                    <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>-->
                  </div>

                  <div class="row mt_small">
                    <select v-model="course_id" class="form-control input-field-font cursor-pointer" >
                      <option :value="null" v-if="reserve_only_seat">席のみ</option>
                      <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
                    </select>
                  </div>
                  <small v-for="error in errors['course_ids']" :key="error" class="error">{{ error }}</small>
                  <div class="row mt_small select-with-label-inside">
                    <span class="label-inside-select single-select-label">滞在分：</span>
                    <select class="form-control input-field-font customer_select cursor-pointer" v-model="stay_minutes">
                      <option v-if="!(new Array(16).fill(0).map((_, i) => i * 15 + 15)).includes(stay_minutes_option)"
                              :value="stay_minutes_option" style="display: none;">{{ stay_minutes_option }}
                      </option>
                      <option v-for="item in new Array(16).fill(0).map((_, i) => i * 15 + 15)" :value="item" :key="item">
                        {{ item }}
                      </option>
                    </select>
                    <small v-for="error in errors['stay_minutes']" :key="error" class="error">{{ error }}</small>
                  </div>

                  <div class="row mt_small">

                    <v-select
                        class="customer-select cursor-pointer"
                        v-model="customer"
                        :options="customers"
                        label="option_label"
                        @input="onChange"
                        v-on:change="onChange"
                        ref="select"
                        placeholder="電話番号(お客様情報検索)"
                    >
                      <template v-slot:search="scope">
                        <input
                            class="vs__search"
                            v-bind="scope.attributes"
                            v-on="scope.events"
                            v-model="searchCustomerKeyword"
                            @input="onSearchCustomer()"
                            :placeholder="customer == null ||  customer.id  === undefined  ? '電話番号(お客様情報検索)': '' "
                        />
                      </template>
                      <span slot="no-options" @click="$refs.select.open = false">
                            表示するデータはありません。
                          </span>
                    </v-select>

                  </div>

                  <div class="row mt_small select-with-label-row without-label">
                    <div class="">
                      <input type="text" class="form-control input-field-font"
                             v-model="last_name" placeholder="せい">
                      <small v-for="error in errors['base']" :key="error" class="error">{{ error }}</small>
                    </div>

                    <div class="">
                      <input type="text" class="form-control input-field-font" v-model="first_name" placeholder="めい">
                      <small v-for="error in errors['first_name']" :key="error" class="error">{{ error }}</small>
                    </div>

                  </div>

                  <div class="row mt_small">
                    <input type="text" class="form-control input-field-font" placeholder="メールアドレス" v-model="email">
                    <small v-for="error in errors['email']" :key="error" class="error">{{ error }}</small>
                  </div>

                  <div class="row mt_small position-relative">
                    <select class="form-control input-field-font cursor-pointer" v-model="staff_name" placeholder="担当者">
                      <option v-if="staff_name_deleted !=null" :value="staff_name_deleted" style="display: none">
                        {{ staff_name_deleted }}
                      </option>
                      <option v-for="item in reservation_staffs" :value="item.name" :key="item.id">{{
                          item.name
                        }}
                      </option>
                    </select>
                    <span v-if="staff_name === undefined" class="label-inside-select single-select-label placeholder" >担当者</span>
                    <span v-if="false" slot="no-options"></span>
                    <small v-for="error in errors['staff_name']" :key="error" class="error">{{ error }}</small>
                  </div>

                  <div class="row mt_small">
                    <textarea class="form-control input-field-font" placeholder="メモ" v-model="memo" rows="3"></textarea>
                    <small v-for="error in errors['memo']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <TagModal
                      @tags-updated="handleTagsUpdated"
                      :clearTag ='clearTag'
                      :reservation="reservation"
                      :selectedTags ="selectedTags"
                  />

                </div>
              </template>

              <!-----------------------EDIT Tabs --------------------->
              <div v-if="!isCreateNew" class="row mt_small">

                <!-- edit form for reservation tab start -->
                <div v-if="showEditForm" class="form-modal-reservation">
                  <div class="test">
                    <div class="row">
                      <label>ステータス</label>
                    </div>


                    <div class="row-item" @click.prevent="showStateModal">
                      <div class="store" :class="dynamicClass">{{getStateText(state)}}</div>
                      <div class="status">ステータス変更</div>
                    </div>
                    <StateChangeModal
                        v-if="showStateChangeModal"
                        @statusSelected="setState"
                        @close="showStateChangeModal = false"
                        :reservation="reservation"
                    />
                  </div>

                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label> お名前</label>
                    </div>
                    <div class="row mt_small select-with-label-row without-label">
                      <div class="">
                        <input type="text" class="form-control input-field-font" placeholder="せい" :class="!last_name ? 'border-danger' : '' " v-model="last_name">
                        <small v-for="error in errors['last_name']" :key="error" class="error">{{ error }}</small>
                      </div>

                      <div class="">
                        <input type="text" class="form-control input-field-font" placeholder="タロウ" v-model="first_name">
                        <small v-for="error in errors['first_name']" :key="error" class="error">{{ error }}</small>
                      </div>
                    </div>
                  </div>

                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>予約に関するメモ</label>
                    </div>
                    <div class="row">
                      <textarea v-model="memo" class="form-control input-field-font memo-area" placeholder="予約に関する備考" rows="3"></textarea>
                    </div>
                    <small v-for="error in errors['memo']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>メモ</label>
                    </div>
                    <div class="row">
                      <textarea class="form-control input-field-font memo-area" placeholder="お客様や接客に関するメモなど" v-model="memo_from_customer" rows="3"></textarea>
                    </div>
                    <small v-for="error in errors['memo_from_customer']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>日付</label>
                    </div>

                    <div class="row select-with-label-row without-label">
                      <div class="select-with-label-inside">
                        <DatePicker
                            input-class="form-control input-field-font cursor-pointer"
                            v-model="start_date"
                            value-type="format"
                            format="YYYY-MM-DD"
                            :clearable="false"
                        >
                          <i class="bi bi-caret-down-fill"></i>
                        </DatePicker>
                      </div>
                      <div class="select-with-label-inside">
                        <DatePicker input-class="form-control input-field-font cursor-pointer" v-model="start_time" value-type="format" format="HH:mm" type="time" :show-second="false" :minute-step="15" :clearable="false">
                          <i class="bi bi-caret-down-fill"></i>
                        </DatePicker>
                        <small v-for="error in errors['start_time']" :key="error" class="error">{{ error }}</small>
                      </div>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>予約人数 </label>
                    </div>
                    <div class="row  select-with-label-row">
                      <div class="select-with-label-inside">
                        <span class="label-inside-select">大人：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_adults" @change="changeNumberPeople">
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>

                      <div class="select-with-label-inside">
                        <span class="label-inside-select">子供：</span>
                        <select class="form-control input-field-font cursor-pointer" v-model="number_of_children" @change="changeNumberPeople">
                          <option :value="0"></option>
                          <option v-for="item in new Array(99).fill(0).map((_, i) => i + 1)" :value="item" :key="item">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>テーブル </label>
                    </div>
                    <div class="row">
                      <v-select v-model="shop_table_ids" :options="shop_tables" :reduce="o => o.id" placeholder="テーブル名" label="full_name" :clearable="false" :searchable="false" :multiple="true" class="form_select_table  without-label" ref="selectMenu" @click.native.stop="forceOpen" :dropdown-should-open="() => dropDownSelection">
                        <template slot="option" slot-scope="option">
                          <input type="checkbox" :checked="isChecked(option.id)" @click.stop="toggleSelection(option)" />
                          {{ option.name }}
                          <span class="second_span">({{ option.number_of_people_min }}~{{
                              option.number_of_people_max
                            }})</span>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                      <span class="single_line first_span">
                        {{ option.name }}
                      </span>
                          <span class="second_span">({{ option.number_of_people_min }}~{{
                              option.number_of_people_max
                            }})</span>
                        </template>
                      </v-select>
                      <small v-for="error in errors['shop_table_ids']" :key="error" class="error">{{ error }}</small>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label> コース </label>
                    </div>
                    <div class="row ">
                      <select v-model="course_id" class="form-control input-field-font cursor-pointer">
                        <option :value="null" v-if="reserve_only_seat">席のみ</option>
                        <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.name }}</option>
                      </select>
                    </div>
                    <small v-for="error in errors['course_ids']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <div class= "reservation-edit-form">
                    <div class="row">
                      <label> 確保時間 </label>
                    </div>
                    <div class="row mt_small select-with-label-inside single-select">
                      <div class="row-item without-label">
                        <span class="sub-time" @click.prevent="decreaseBy30"> -30分</span>
                        <span class="sub-time" @click.prevent="decreaseBy15"> -15分</span>
                        <span class="stay-time">{{stay_minutes}}分</span>
                        <span class="add-time" @click.prevent="increaseBy15"> +15分</span>
                        <span class="add-time" @click.prevent="increaseBy30"> +30分</span>
                        <button class="undo-btn" @click.prevent="undoStayTime">元に戻す</button>
                      </div>

                    </div>
                    <small v-for="error in errors['stay_minutes']" :key="error" class="error">{{ error }}</small>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label>お客様情報検索 </label>
                    </div>
                    <div class="row mt_small">
                      <v-select class="customer-select cursor-pointer  without-label" v-model="customer" :options="customers" label="option_label" @input="onChange" v-on:change="onChange" ref="select" placeholder="電話番号を入力">
                        <template v-slot:search="scope">
                          <input class="vs__search no-options" v-bind="scope.attributes" v-on="scope.events" v-model="searchCustomerKeyword" @input="onSearchCustomer()" :placeholder="customer == null ||  customer.id  === undefined  ? '電話番号(お客様情報検索)': '' " />
                        </template>
                        <span slot="no-options" @click="$refs.select.open = false">
                                    表示するデータはありません。
                                  </span>
                      </v-select>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">

                    <div class="row">
                      <label> メールアドレス </label>
                    </div>
                    <div class="row mt_small">
                      <input type="text" class="form-control input-field-font" placeholder="メールアドレス" v-model="email">
                      <small v-for="error in errors['email']" :key="error" class="error">{{ error }}</small>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label> 流入経路 </label>
                    </div>
                    <div class="row mt_small select-with-label-inside single-select">
                      <select class="form-control input-field-font cursor-pointer" id="reservation-types" v-model="reservation_type">
                        <option v-for="item in reservation_type_options" :value="item.value" :key="item.value" v-if="item.value != 'pos' || is_shop_pos">
                          {{ item.text }}
                        </option>
                      </select>
                      <small v-for="error in errors['reservation_type']" :key="error" class="error">{{ error }}</small>
                    </div>
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row">
                      <label> 予約担当者 </label>
                    </div>
                    <div class="row mt_small select-with-label-inside single-select">
                      <select class="form-control input-field-font customer_select cursor-pointer" v-model="staff_name">
                        <option v-if="staff_name_deleted !=null" :value="staff_name_deleted" style="display: none">
                          {{ staff_name_deleted }}
                        </option>
                        <option v-for="item in reservation_staffs" :value="item.name" :key="item.id">{{
                            item.name
                          }}
                        </option>
                      </select>
                      <span slot="no-options"></span>
                      <small v-for="error in errors['staff_name']" :key="error" class="error">{{ error }}</small>
                    </div>
                  </div>

                  <div class ="reservation-edit-form">
                    <TagModal @tags-updated="handleTagsUpdated" :clearTag ='clearTag' :reservation="reservation" :selectedTags="selectedTags" />
                  </div>
                  <div class ="reservation-edit-form">
                    <div class="row reservation-date-info">
                      <div class="column">
                        <div class="row">
                          <label>受付日時</label>
                        </div>
                        <div class="row">
                          <input type="text" readonly="readonly" class="form-control input-field-font"  v-model="createdAt">
                        </div>
                      </div>
                      <div class="column">
                        <div class="row">
                          <label>変更日時</label>
                        </div>
                        <div class="row">
                          <input type="text" readonly="readonly" class="form-control input-field-font"  v-model="updatedAt">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- edit form for reservation tab end -->

                <!-- read only form for reservation start -->
                <div v-if="!showEditForm" class="form-modal-reservation">
                  <ReservationView :reservation="reservation" :showView="!showEditForm" :tab="tab"/>
                </div>
                <!-- read only form for reservation end -->
              </div>

            </div>

            <!---------------- RESERVATION EMAIL INFORMATION TAB ------------------->
            <div v-if="tab === 'reservation_email'" class="form-modal-reservation">
              <div class="row">
                <div class="col-md-6">
                  <label>自動連携日時</label>
                  <input type="text" class="form-control input-field-font reservation-email" v-model="reservation.ses_import_data.received_at" readonly>
                </div>
                <div class="col-md-6">
                  <label>流入経路</label>
                  <input type="text" class="form-control input-field-font reservation-email" v-model="reservation.ses_import_data.from_system" readonly>
                </div>
              </div>
              <div class="col-md-12">
                <label>メール本文</label>
                <textarea class="form-control reservation-email" rows="25" v-html="reservation.ses_import_data.content_mail" readonly></textarea>
              </div>
            </div>
            <!---------------- RESERVATION EMAIL INFORMATION TAB END ------------------->

            <!---------------- EATING INFORMATION TAB ------------------->
            <div v-if="tab === 'pos_order'">
              <div v-if="reservation.customer_id != null">
                <div class="header-res">
                  <div class="col-res">
                    日付
                  </div>
                  <div class="col-res">
                    品数
                  </div>
                  <div class="col-res">
                    金額
                  </div>
                </div>

                <div v-for="(r, index) in reservations" v-bind:key="index">
                  <div v-if="r.pos_order_data">
                    <button class="accordion" :id="'accordion-' + index" :key="index"
                            @click="showItemPosData($event, index)">
                      <div class="header-res content-res">
                        <div class="col-res">
                          {{ convertTime(r.start_time) }}
                        </div>
                        <div class="col-res left-padded">
                          {{ r.pos_order_data.items.length > 0 ? r.pos_order_data.quantity : '' }}
                        </div>
                        <div v-if="r.pos_order_data.items.length > 0" class="col-res left-padded">
                          ￥{{ Intl.NumberFormat('ja-JP').format(r.pos_order_data.sum) }}
                        </div>
                        <div v-else class="col-res" style="margin: 10px 0 10px 30px;"></div>
                        <div class="col-res-4 icon-acc">
                        </div>
                      </div>
                    </button>
                    <div class="panel" :id="'panel-' + index">
                      <div class="margin-top-bottom">伝票番号：{{ r.pos_order_data.order_no }}</div>
                      <div class="margin-top-bottom">
                        会計時刻：{{ formartPaymentDT(r.pos_order_data.payment_dttm) }}
                      </div>
                      <div v-for="item in r.pos_order_data.items" v-bind:key="item.name">
                        <div class="accordion-content">
                          <div class="col-res-1 w-30-res">
                            {{ item.name }}
                          </div>
                          <div class="col-res-2">
                            {{ item.quantity }}
                          </div>
                          <div class="col-res-3 w-27-res">
                            ￥{{ Intl.NumberFormat('ja-JP').format(item.price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom-res"></div>
                  </div>
                </div>
              </div>

              <div v-else class="panel">
                <div class="info_adjust-item">

                  <div class="margin-top-bottom">伝票番号：{{
                      reservation.pos_order_data.order_no
                    }}
                  </div>
                  <div class="margin-top-bottom">
                   会計時刻：{{ formartPaymentDT(reservation.pos_order_data.payment_dttm) }}
                  </div>
                  <div class="item-container">
                    <div v-for="item in reservation.pos_order_data.items" v-bind:key="item.name">
                      <div class="accordion-content">
                        <div class="col-res-1 w-30-res ml-0">
                          {{ item.name }}
                        </div>
                        <div class="col-res-2">
                          {{ item.quantity }}
                        </div>
                        <div class="col-res-3 w-27-res">
                          ￥{{ Intl.NumberFormat('ja-JP').format(item.price) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-content">
                    <div class="col-res-1 w-30-res ml-0">
                      会計金額
                    </div>
                    <div class="col-res-2"></div>
                    <div class="col-res-3 w-27-res">
                      ￥{{ Intl.NumberFormat('ja-JP').format(reservation.pos_order_data.sum) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!---------------- EATING INFORMATION TAB END------------------->

          </div>

          <div class="modal-footer" v-if="tab!=='customer'">
            <button v-if="reservation.mergeable_reservations && reservation.mergeable_reservations.length" type="button"
                    class="btn btn-primary mr_small" @click="onOpenMergeModal(reservation)">POSレジ連携を予約と結合
            </button>
            <button v-if="tab === 'reservation' && reservation.state== 'cancelled' && !isCreateNew && showEditForm" type="button" class="btn btn-primary mr_small"
                    @click="onRestore">
              復元
            </button>
            <button v-if="isCreateNew" type="button" class="btn btn-primary mr_small" @click="onSave">
              {{ isCreateNew ? '登録' : '更新' }}する
            </button>
            <button v-if="tab === 'reservation' && reservation.state!= 'cancelled' && !isCreateNew && showEditForm" type="button"
                    class="btn btn-primary" @click="onSave">
              保存する
            </button>

            <button v-if="!showEditForm && !isCreateNew && tab==='reservation'" type="button"
                    class="btn btn-primary" @click="showEditPage">
              編集する
            </button>
            <div v-if="version">
              <button class="btn btn-outline-primary" @click="getReservationHistoryDetails(version.id)" type="button">受付履歴</button>
            </div>
          </div>
        </div>
        <!-- Commented for now for new show page -->
        <!-- <CustomerForm v-show="tab === 'customer'" ref="customerForm" :customer="customerEdit"
                       :reservations="reservations"
                       :isCreateNew="isCreateNew" @changeData="isChangeData = true" @loading="$emit('loading', $event)"
                       @submitSuccess="submitSuccess">
         </CustomerForm> -->

        <CustomerView v-show="tab === 'customer'" ref="customerForm" :customer="customerEdit"
                      :reservations="reservations"
                      :isCreateNew="isCreateNew" @changeData="isChangeData = true" @loading="$emit('loading', $event)"
                      @submitSuccess="submitSuccess">
        </CustomerView>
      </div>
      <div class="form-modal-reservation" v-if="tab == 'ses_mail'">
        <div class="row mt_small">
          <div class="col-md-6">
            <label>自動連携日時</label>
            <input type="text" class="form-control input-field-font" v-model="reservation.ses_import_data.received_at">
          </div>
          <div class="col-md-6">
            <label>流入経路</label>
            <input type="text" class="form-control input-field-font" v-model="reservation.ses_import_data.from_system">
          </div>
          <div class="col-md-12 mt_small">
            <label>メール本文</label>
            <textarea class="form-control input-field-font" rows="18" style="max-height: none; line-height: 1.4rem; font-size: 14px;"
                      v-html="reservation.ses_import_data.content_mail"></textarea>
          </div>
        </div>
      </div>
    </div>

    <CourseModal
        v-if="showCourseModal && tab==='reservation'"
        :course="course"
        @close="showCourseModal = false"
    >
    </CourseModal>
    <ModalConfirmClose
        v-if="showModalConfirmClose"
        @close="showModalConfirmClose=false"
        @confirm="onConfirmClose"
    >
    </ModalConfirmClose>
  </div>


</template>

<script>
import moment from 'moment';
import {decode_data} from "../../shared/util";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Http from "../../shared/http"
import CourseModal from './CourseModal.vue';
import ModalConfirmClose from './ModalConfirmClose.vue'
import Validator from "../../src/validator"
import CustomerForm from './CustomerForm.vue';
import CustomerView from './CustomerView.vue';
import _ from 'lodash';
import TagModal from "./TagModal.vue";
import StateChangeModal from "./StateChangeModal.vue"
import ReservationView from "./ReservationView.vue"

export default {
  components: {TagModal, DatePicker, vSelect, CourseModal, ModalConfirmClose, CustomerForm, CustomerView, ReservationView,StateChangeModal },
  props: {
    reservation: {
      type: Object,
    },
    customerEdit: {
      type: Object,
    },
    showReservationTags: {
      type: Array,
    },
    reservations: {
      type: Array,
    },
    fromWhere:{
      type:String,
    },
    showResverveModal:{
      type: Boolean
    },
    insideFifteenMinute:{
      type:Boolean
    }
  },
  data() {
    return {
      version: [],
      showEditForm:'',
      clearTag: true,
      reserve_only_seat: '',
      showCancelButton: true,
      showAccountedForButton: false,
      showThreeButton: false,
      course: {},
      course_name:'',
      showWarning: false,
      dropDownSelection: false,
      showModalStatus: false,
      showAllReservation: false,
      showStatus: false,
      showCourseModal: false,
      course_id: null,
      customer_id: null,
      customer: {},
      customers_search: [],
      tab: 'reservation',
      mode: '',
      is_course: 0,
      shop_table_ids: [],
      number_of_people: '',
      number_of_adults: '',
      number_of_children: '',
      stay_minutes: '',
      stay_minutes_option: '',
      start_date: '',
      start_time: '',
      reservation_type: '',
      last_name: '',
      first_name: '',
      last_name_kana: '',
      first_name_kana: '',
      tel: '',
      email: '',
      memo: '',
      memo_from_customer: '',
      createdAt: null,
      updatedAt: null,
      staff_name: '',
      staff_name_deleted: '',
      is_staff_name: false,
      state: '',
      errors: {},
      client_name: '',
      is_shop_pos: false,
      selectedTags: [],
      shop_tables: decode_data("#data-shop-tables"),
      reservation_staffs: decode_data("#data-reservation-staffs"),
      shop: decode_data("#data-shop"),
      reservation_type_options: [
        {
          text: '電話',
          value: 'phone',
        },
        {
          text: '管理者',
          value: 'admin',
        },
        {
          text: 'Table Request',
          value: 'tablerequest',
        },
        {
          text: 'Instagram',
          value: 'instagram',
        },
        {
          text: 'Google',
          value: 'google',
        },
        {
          text: 'ウォークイン',
          value: 'walkin',
        },
        {
          text: 'POSレジ連携',
          value: 'pos',
        },
        {
          text: 'ぐるなび',
          value: 'gurunavi',
        },
        {
          text: 'ホットペッパー',
          value: 'hotpepper',
        },
        {
          text: '一休',
          value: 'ikyu',
        },
        {
          text: '食べログ',
          value: 'tabelog',
        },
        {
          text: 'Tablecheck',
          value: 'tablecheck',
        },
        {
          text: 'ヒトサラ',
          value: 'hitosara',
        },
        {
          text: 'トレタ',
          value: 'toreta',
        },
        {
          text: 'Line',
          value: 'line',
        },
        {
          text: 'Alipay',
          value: 'alipay',
        },
        {
          text: 'Aumo',
          value: 'aumo',
        },
      ],
      state_options: [
        {
          text: '来店待ち',
          value: 'reserved',
        },
        {
          text: '来店',
          value: 'seating',
        },
        {
          text: '会計済',
          value: 'finished',
        },
        {
          text: 'No Show',
          value: 'not_show',
        },
        {
          text: 'キャンセル',
          value: 'cancelled',
        },
      ],
      courses: [],
      checkPos: false,
      checkCustomer: false,
      showPosData: false,
      showModalConfirmClose: false,
      isCustomerChangeData: false,
      isChangeData: false,
      reservationDefault: {},
      searchCustomerKeyword: null,
      customers: [],
      historyStayMinute:[],
      showStateChangeModal: false,
      reserve_only_seat:false,

    };
  },
  computed: {
    isCreateNew() {
      return !this.reservation.id;
    },
    isChecked() {
      return (optionId) => this.shop_table_ids && this.shop_table_ids.includes(optionId);
    },
    dynamicClass(){
      return this.state
    }
  },

  created(){
    if(this.fromWhere==='fromTimeTable'){
      this.showEditForm=false
      this.reservation.re_trigger=moment()
    }
    if(this.fromWhere==='fromReservationList'){
      this.showEditForm=true
      this.reservation.re_trigger=moment()
    }
  },

  watch: {
    reservation: {
      handler(r) {
        this.mode = this.isCreateNew && r.reservation_type === 'walkin' ? 'walkin' : 'normal'
        this.shop_table_ids = r.shop_table_ids
        this.number_of_adults = r.number_of_adults === 0 ? r.number_of_people : r.number_of_adults
        this.number_of_children = r.number_of_children ? r.number_of_children : 0
        this.number_of_people = r.number_of_people
        this.stay_minutes = r.stay_minutes
        this.stay_minutes_option = r.stay_minutes
        this.start_date = moment(r.start_time).format('YYYY-MM-DD')
        this.getCourses(moment(r.start_time).format('YYYY-MM-DD'))
        this.start_time = r.is_block ? null : moment(r.start_time).format('HH:mm')
        this.reservation_type = r.reservation_type
        this.checkCustomer = r.customer_id !== null ? true : false
        this.checkPos = r.pos_order_data !== null ? true : false
        this.last_name = r.last_name
        this.first_name = r.first_name
        this.last_name_kana = r.last_name_kana
        this.first_name_kana = r.first_name_kana
        this.tel = r.tel
        this.email = r.email
        this.memo = r.memo
        this.memo_from_customer = r.memo_from_customer
        this.staff_name = r.staff_name
        this.reservation_type = r.reservation_type
        this.state = r.state
        this.client_name = r.client_name
        this.course_id = r.course_id === undefined ? null : r.course_id
        this.course_name = r.course_name
        this.selectedTags = r.tag_details
        if (this.course_id) {
          this.getCourse(this.course_id)
          this.is_course = 1
        } else {
          this.is_course = 0
        }
        this.createdAt = moment(r.created_at).format('YYYY-MM-DD HH:mm')
        this.updatedAt = moment(r.created_at).format('YYYY-MM-DD HH:mm:ss') !== moment(r.updated_at).format('YYYY-MM-DD HH:mm:ss') ? moment(r.updated_at).format('YYYY-MM-DD HH:mm') : ''

        if (this.reservation_type == 'web') {
          this.reservation_type = 'tablerequest'
        }

        this.tab = 'reservation';
        this.showCourseModal = false;
        this.showAllReservation = false;
        this.showStatus = false;
        this.showModalStatus = false;
        this.showWarning = false;
        this.errors = {}
        this.customer = {}
        this.customer_id = null
        this.checkStaffName()
        this.getVersionsWithDetails(r.id)
        this.scrollTopScreen()
        this.showModalConfirmClose = false
        this.isChangeData = false
        this.isCustomerChangeData = false

        this.reservationDefault.reservation_type = this.reservation_type
        this.reservationDefault.state = this.state
        this.reservationDefault.last_name = this.last_name
      },
      deep: true,
    },

    shop_table_ids(newValue) {
      if (newValue != this.reservation.shop_table_ids) {
        this.isChangeData = true;
      }
    },

    course_id(newValue) {
      if (newValue != this.reservation.course_id) {
        this.isChangeData = true;
      }
    },

    stay_minutes(newValue) {
      if (newValue != this.reservation.stay_minutes) {
        this.isChangeData = true;
      }
    },

    number_of_adults(newValue) {
      if (newValue != (this.reservation.number_of_adults === 0 ? this.reservation.number_of_people : this.reservation.number_of_adults)) {
        this.isChangeData = true;
      }
    },

    number_of_children(newValue) {
      if (newValue != (this.reservation.number_of_children ? this.reservation.number_of_children : 0)) {
        this.isChangeData = true;
      }
    },

    start_time(newValue) {
      if (newValue != moment(this.reservation.start_time).format('HH:mm')) {
        this.isChangeData = true;
      }
    },

    first_name(newValue) {
      if (newValue != this.reservation.first_name) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    last_name_kana(newValue) {
      if (newValue != this.reservation.last_name_kana) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    first_name_kana(newValue) {
      if (newValue != this.reservation.first_name_kana) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    tel(newValue) {
      if (newValue != this.reservation.tel) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    email(newValue) {
      if (newValue != this.reservation.email) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    staff_name(newValue) {
      if (newValue != this.reservation.staff_name) {
        this.isChangeData = true;
      }
    },

    memo(newValue) {
      if (newValue != this.reservation.memo) {
        this.isChangeData = true;
      }
    },

    last_name(newValue) {
      if (newValue != this.reservationDefault.last_name) {
        this.isChangeData = true;
        this.isCustomerChangeData = true;
      }
    },

    reservation_type(newValue) {
      if (newValue != this.reservationDefault.reservation_type) {
        this.isChangeData = true;
      }
    },

    state(newValue) {
      if (newValue != this.reservationDefault.state) {
        this.isChangeData = true;
      }
    },

    mode(newValue, oldValue) {
      if (this.isCreateNew && oldValue === 'walkin' && newValue === 'normal') {
        this.reservation_type = 'phone'
        this.last_name = ''
        this.state = 'reserved'
      }
      if (this.isCreateNew && oldValue === 'normal' && newValue === 'walkin') {
        this.reservation_type = 'walkin'
        this.last_name = 'ウォークイン'
        this.state = 'seating'
      }
      if (this.isCreateNew && !oldValue && newValue==='normal'){
        this.reservation_type = 'phone'
      }
      this.reservationDefault.reservation_type = this.reservation_type
      this.reservationDefault.state = this.state
      this.reservationDefault.last_name = this.last_name
    },
    async start_date(val) {
      await this.getCourses(val);
      const course_id_select = this.course_id;
      const course = this.courses.filter(function (cours) {
        return (cours.id == course_id_select);
      })

      this.course_id = course.length > 0 ? this.course_id : null;

      if (val != moment(this.reservation.start_time).format('YYYY-MM-DD')) {
        this.isChangeData = true;
      }
    },
    is_course(val) {
      if (val == 0) {
        this.course_id = null;
      }
      if (val != (this.course_id ? 1 : 0)) {
        this.isChangeData = true;
      }
    }
  },
  async mounted() {
    await this.checkPost();
    document.addEventListener('click', this.checkOutsideClick);
    await this.getCustomers();
  },
  methods: {

    async getReservationHistoryDetails(id) {
      // Redirect to the reservation history details page
      this.loading = true;
      window.location.href = `/shop_manager/reservation/history/${id}`;
    },
    async getVersionsWithDetails(id) {
      try {
        this.loading = true;
        const response = await fetch(`/shop_manager/reservation/history`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        const jsonResponse = await response.json();

        if (jsonResponse.versions) {

          this.version = jsonResponse.versions.find(item => item.item_id === id);  // Now assigning 'versions'
          this.loading = false;
        } else {
          console.error("Invalid response structure:", jsonResponse);
          this.loading = false;
        }
      } catch (error) {
        console.error("Error fetching versions:", error);
        this.loading = false;
      }
    },
    isToday(start_date) {
      const currentDate = new Date();
      const startDate = new Date(start_date);
      const isToday = startDate.toDateString() === currentDate.toDateString();
      return isToday;
    },
    getCourseName(course_id){
      const course = this.courses.find((item)=>item.id==course_id)?.name
      return  course
    },
    getStateText(state) {
      switch (state) {
        case 'reserved':
          return '来店待ち';
        case 'seating':
          return '来店';
        case 'finished':
          return '会計済';
        case 'not_show':
          return 'No Show';
        case 'cancelled':
          return '予約取消';
        default:
          return '';
      }
    },
    onSearchCustomer: _.debounce(async function () {
      await this.getCustomers();

      // Check if any customers are found
      if (this.customers.length === true) {
        const foundCustomer = this.customers.find(
            (customer) =>
                customer.option_label.toLowerCase().includes(
                    this.searchCustomerKeyword.toLowerCase()
                )
        );

        if (foundCustomer) {
          // Update customer if found
          this.customer = foundCustomer;
          this.tel = null; // Reset telephone if a customer is found
        } else {
          // Reset selected customer if not found
          this.customer = null;
          // Use search input as telephone
          this.tel = this.searchCustomerKeyword;
        }
      } else {
        // No customers found, treat the input as a telephone number
        this.customer = null;
        this.tel = this.searchCustomerKeyword;
      }
    }, 300),


    async getCustomers() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/customer/reservation_search`;

      await Http.get(path, {
        keyword: this.searchCustomerKeyword
      })
          .then((response) => {
            this.customers = response.data.customers;
            this.customers.map(function (c) {
              const telephone = c.tel ? c.tel + '/' : '';
              const tel2 = c.tel1 ? c.tel1 + '/' : '';
              const fullName = c.last_name + (c.first_name ? ' ' + c.first_name : '');
              const remark = c.remark ? '/' + c.remark : '';
              return c.option_label = telephone + tel2 + fullName + remark;
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toggleSelection(option) {
      if (this.shop_table_ids.includes(option.id)) {
        this.shop_table_ids = this.shop_table_ids.filter(id => id !== option.id);
      } else {
        this.shop_table_ids = [...this.shop_table_ids, option.id];
      }
    },
    checkOutsideClick(event) {
      if (this.dropDownSelection && this.$refs.selectMenu && !this.$refs.selectMenu.$el.contains(event.target)) {
        this.outsideClick();
      }
    },
    forceOpen() {
      this.$refs.selectMenu.isMenuActive = true;
      this.dropDownSelection = true
    },
    outsideClick() {
      this.$refs.selectMenu.isMenuActive = false;
      this.dropDownSelection = false
    },

    changeState(state) {
      const newStateValue = state

      // Reassign the state
      this.state = newStateValue;

      // Update other properties
      this.showWarning = this.state === 'cancelled';
      this.showStatus = false;

      // Run onSave
      this.onSave();
    }
    ,
    changeNumberPeople() {
      if (this.number_of_adults == null) return;
      this.number_of_people = this.number_of_adults + this.number_of_children;
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('modal-body');
      elmnt.scrollTo(0, 0);
    },
    getImageDefault() {
      let path = window.location.href;
      if (path.includes('https://book.stg.tablerequest.jp/')) {
        return 'https://book-tablerequest-stg.s3.ap-northeast-1.amazonaws.com/images/shops/default_image.png';
      } else {
        return 'https://book-tablerequest-prd.s3.ap-northeast-1.amazonaws.com/images/shops/default_image.png';
      }
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    checkStaffName() {
      this.staff_name_deleted = null;
      if (this.reservation_staffs.find(e => e.name == this.staff_name) == null) {
        this.staff_name_deleted = this.staff_name;
      }
    },
    onClose() {
      this.searchCustomerKeyword='';
      if (this.isChangeData) {
        this.showModalConfirmClose = true;
      } else {
        this.$emit('close');
      }
      if(this.fromWhere==='fromTimeTable'){
        this.showEditForm=false;
      }
      if(this.fromWhere==='fromReservationList'){
        this.showEditForm=true;
      }
    },
    onConfirmClose() {
      this.$emit('close');
    },
    onChange() {
      if (this.customer != null) {
        this.customer_id = this.customer.id;
        this.first_name = this.customer.first_name;
        this.last_name = this.customer.last_name;
        this.first_name_kana = this.customer.first_name_kana;
        this.last_name_kana = this.customer.last_name_kana;
        this.tel = this.customer.tel;
        this.email = this.customer.email;
      } else {
        this.customer_id = null;
        this.first_name = '';
        this.last_name = '';
        this.first_name_kana = '';
        this.last_name_kana = '';
        this.tel = '';
        this.email = '';
      }

      this.searchCustomerKeyword = null;
      this.getCustomers();
    },
    async checkPost() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/is_shop_pos`;
      await Http.get(path)
          .then((response) => {
            this.is_shop_pos = response.data.is_shop_pos;
          })
          .catch((error) => {
            this.errors = error.response.data
          });
    },

    async onStateChangeToCancel() {
      this.$emit('loading', false);
      const {last_name, last_name_kana, first_name, first_name_kana, shop, start_date, start_time, number_of_people} = this;
      const prompt = await this.$swal({
        title: "お客様へキャンセル通知を送信しますか？",
        html: `
            <div class="swal2-content" style="font-size: 14px; margin: 0 32px; text-align: left; border: 1px solid black; padding: 10px;">
              <p>${last_name || last_name_kana} ${first_name || first_name_kana}様</p>
              <p>${shop.name} です。</p>
              <p>下記ご予約につきまして、誠に勝手ながらお店よりキャンセルさせていただきました。</p>
              <br>
              <p>予約日時：${start_date} ${start_time}</p>
              <p>予約人数：${number_of_people}</p>
              <p>予約者名：${last_name || last_name_kana} ${first_name || first_name_kana}</p>
              <p>店舗名：${shop.name}</p>
              <p>電話番号：${shop.tel}</p>
              <br>
              <p>誠に恐れ入りますが、ご了承のほど何卒お願い申し上げます。</p>
              <br>
              <p>※本メールは送信専用のため、返信内容を確認できません。</p>
              <p>※ご不明な点がございましたら恐れ入りますが、お店に直接ご連絡くださいますようお願い申し上げます。当メールにご返信いただいても、お店には届きません。</p>
            </div>`,
        icon: "warning",
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        showCancelButton: true,
        confirmButtonText: "送信する",
        cancelButtonText: "送信しない",
        buttonsStyling: false,
      });

      if (prompt.isConfirmed) {
        await this.save_reservation(true);
      } else {
        await this.save_reservation()
      }
    },

    async onRestore() {
      this.validate();
      if (Object.keys(this.errors).length) {
        return;
      }

      if (this.course_id != null && this.isCreateNew) {
        this.is_course = 1;
      } else {
        this.is_course = 0
      }
      this.$emit('loading', true);
      await this.save_reservation();
    },

    async save_reservation(send_mail = false) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = this.isCreateNew ? `${slug}/shop_manager/time_table/` : `${slug}/shop_manager/time_table/${this.reservation.id}`

      await Http.post(path, {
        reservation: {
          is_course: this.is_course,
          shop_table_ids: this.shop_table_ids,
          start_time: `${this.start_date} ${this.start_time}`,
          stay_minutes: this.stay_minutes,
          number_of_people: this.number_of_people,
          number_of_adults: this.number_of_adults,
          number_of_children: this.number_of_children,
          reservation_type: this.reservation_type == 'app_tablerequest' ? 'web' : this.reservation_type,
          first_name: this.first_name || '',
          last_name: this.last_name,
          first_name_kana: this.first_name_kana,
          last_name_kana: this.last_name_kana,
          tel: this.tel,
          email: this.email,
          staff_name: this.staff_name,
          memo: this.memo,
          memo_from_customer: this.memo_from_customer,
          reset_time: this.reset_time,
          state:(this.isCreateNew && this.fromWhere !== "fromReservationList")
              ? (this.insideFifteenMinute ? "seating" : "reserved")
              : this.state,
          tags_id:this.selectedTags,
          course_ids: this.course_id == null ? [] : [this.course_id],
        },
        update_user_data: this.isCustomerChangeData,
        customer_id: this.customer_id,
        send_mail: send_mail,
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close')
          })
          .catch((error) => {
            this.$emit('loading', false);
            this.errors = error.response.data
          });
    },

    async onSave() {
      if (this.tab === 'customer') {
        const customerForm = this.$refs.customerForm;
        customerForm.onSave();
        return;
      }
      this.validate();
      if (Object.keys(this.errors).length) {
        return;
      }

      if (this.course_id != null && this.isCreateNew) {
        this.is_course = 1;
      } else {
        this.is_course = 0
      }

      if (this.is_course === '0' && this.isCreateNew) {
        this.course_id = null;
      }

      this.$emit('loading', true);

      if ((this.state === 'cancelled' || this.state === 'not_show') && !(this.email === null || this.email === '')) {
        await this.onStateChangeToCancel();
      } else {
        await this.save_reservation()
      }
      if(this.fromWhere==='fromTimeTable'){
        if(!(Object.keys(this.errors).length)){
          this.showEditForm=false;
        }
      }
      if(this.fromWhere==='fromReservationList'){
        this.showEditForm=true;
      }

    },
    displayAllergy(allergy_name, allergy_remark) {
      var name = '';
      allergy_name.forEach(element => {
        if (element != 'その他') {
          name = name + element + '、';
        }
      });
      if (allergy_remark != null) {
        name = name + allergy_remark;
      }
      if (name[name.length] == '、') {
        name = name.slice(0, -1);
      }
      return name;
    },
    displayProperties(property_name) {
      var name = '';
      property_name.forEach(element => {
        name = name + element + '、';
      });
      if (name[name.length - 1] == '、') {
        name = name.slice(0, -1);
      }
      return name;
    },
    async onDelete() {
      const prompt = await this.$swal({
        title: "予約をキャンセルしますか？",
        html: '<p class="swal2-content" style="font-size: 14px; margin: 0 32px;">キャンセルをした予約を復元する場合は、メニューの「予約一覧」から行えます。</p>',
        icon: "warning",
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-danger mr_small',
          cancelButton: 'btn btn-outline-primary'
        },
        showCancelButton: true,
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
        buttonsStyling: false,
      });
      if (prompt.isConfirmed) {
        const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
        await Http.patch(`${slug}/shop_manager/time_table/${this.reservation.id}/cancel`, {})
            .then((response) => {
              this.$emit('reload')
              this.$emit('close')
            })
            .catch((error) => {
              console.log(error)
            });
      }
    },
    posOrderDetail() {
      const items_detail = this.reservation.pos_order_data.items.map(item => `${item.name}　${item.quantity}　${item.price}円`).join("\n");
      const payment_dttm = this.reservation.pos_order_data.payment_dttm ? moment(this.reservation.pos_order_data.payment_dttm || '').format('YYYY/MM/DD HH:mm') : '';
      return `伝票番号：${this.reservation.pos_order_data?.order_no}
    会計時刻：${payment_dttm}
    ------------------------------------------------
    ${items_detail}
    ------------------------------------------------
    会計 ${this.reservation.pos_order_data.sum} 円
    `
    },
    onOpenMergeModal(reservation) {
      this.$emit('close')
      this.$emit('openMergeModal', reservation)
    },
    changeStayTime($event) {
      if (this.course_id !== null) {
        this.getCourse(this.course_id)
        const course = this.courses.filter(function (val) {
          return (val.id == $event);
        })
        this.stay_minutes = course[0].stay_time
      }
    },
    changeShowCourseModal() {
      if (this.showCourseModal) {
        this.showCourseModal = false;
      } else {
        this.showCourseModal = true;
        this.showStatus = true;
      }
    },
    showItemPosData($event, index) {
      document.getElementById(`accordion-${index}`).classList.toggle("active")
      const panel = document.getElementById(`panel-${index}`);
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    formartPaymentDT(date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : '';
    },
    async getCourses(date) {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/course/get_courses`;

      await Http.get(path, {
        date: date
      })
          .then((response) => {
            this.courses = response.data.courses;
            this.reserve_only_seat= response.data.reserve_only_seat
            this.$emit('loading', false)
          })
          .catch((error) => {
            console.log(error);
            this.$emit('loading', false)
          });
    },
    async getCourse(course_id) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/course/show_course/${course_id}`;

      await Http.get(path, {})
          .then((response) => {
            this.course = response.data.course;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    handleTagsUpdated(selectedTagIds) {
      this.selectedTags = selectedTagIds;
    },
    validate() {
      const validator = new Validator();
      validator.checkRequire('time', this.start_time, '時分')
      this.errors = validator.errors;
    },
    submitSuccess() {
      this.$emit('reload');
      this.$emit('close');
    },
    showEditPage() {
      this.showEditForm=true;
      this.openReservation(this.reservation)
    },
    decreaseBy30(){
      this.historyStayMinute.push(this.reservation.stay_minutes);
      this.stay_minutes -=30;
    },
    decreaseBy15(){
      this.historyStayMinute.push(this.stay_minutes);
      this.stay_minutes -=15;
    },
    increaseBy30(){
      this.historyStayMinute.push(this.stay_minutes);
      this.stay_minutes +=30;
    },
    increaseBy15(){
      this.historyStayMinute.push(this.stay_minutes);
      this.stay_minutes +=15;
    },
    undoStayTime(){
      if (this.historyStayMinute.length > 0) {
        this.stay_minutes = this.historyStayMinute.pop();
      }
    },
    async openReservation(reservation) {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.get(`${slug}/shop_manager/time_table/${reservation.id}`, {})
          .then((response) => {
            this.$emit('loading', false)
            this.$emit('change-reservation', response.data.reservation)

          })
          .catch((error) => {
            this.$emit('loading', false)
          });
    },
    changeStatus(state){
      console.log(state)
    },
    showStateModal() {
      this.showStateChangeModal = true;
    },
    setState(val) {
      this.state = val.value;
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  background-color: #2125298a;
}

.modal-mb {
  margin-bottom: calc(100vh - 98%);
}

.pd-0 {
  padding: 0 0 20px 0 !important;
}

.btn-unset {
  background: transparent;
  border: 2px solid var(--color-main) !important;
  color: var(--color-main);
  font-size: 16px;
}

.modal-header, .modal-body {
  padding: 0.6rem 1rem;
}

.modal-dialog {
  z-index: 1050;
  max-width: 630px;
}

.mx-datepicker {
  width: 100%;
}

#reservation-types option:disabled {
  color: #999;
}

.display-none {
  display: none !important;
}

.disable {
  background: #cccccc;
  cursor: not-allowed;
  pointer-events: none;
}
.disable-tab {
  cursor: not-allowed;
  pointer-events: none;
}

.customer_table {
  width: 100%;
  font-size: 15px;

  th {
    width: 33%;
    background-color: var(--color-main);
    color: #fff;
    font-weight: unset;
    padding: 10px 0px 10px 60px;
  }
;

  td {
    padding: 5px 0px 5px 60px;
    border-bottom: 1px solid #DDDDDD;
  }
}

.customer-details {
  padding-top: 10px;
  font-size: 16px;
  display: flex;

  .label {
    font-weight: 600;
  }

  .count-reservation {
    margin-left: 10px;
  }
}

.reserved {
  border: 2px solid var(--sub-color);
  color: var(--color-reserved-border);
  background-color:var( --color-reserved-opacity);
}

.finished {
  border: 2px solid var(--color-medium-grey);
  background-color: var(--color-accounted-for);
  color: var(--color-medium-grey);
}

.not_show {
  background-color: var(--color-no-show);
  color: var(--color-white);
}

.cancelled {
  background-color: var(--color-reservation-cancel);
  color: var(--color-white);
}

.button_state {
  width: 22%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }
}

.text_state {
  text-align: center;
  width: 100%;
}

.modal-header {
  align-items: center;
}

.image_set {
  object-fit: cover;
}

.box_list {
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
}

.list_status {
  width: 100%;
  padding-left: 20px;
}

.customer-details {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.customer-right-details {
  text-align: right;
  color: blue;
  font-size: 16px;
}

.column-1 {
  padding-left: 40px;
  width: 160px;
}

.column-2 {
  width: 110px;
}

.column-3 {
  width: 155px;
  margin-left: 10px;
}

.column-4 {
  margin-left: 10px;
  width: 135px;
}

.d-flex-content {
  display: flex;
}

.reservation_null {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn{
  font-size:16px !important;
}
@media screen and (max-width: 480px) {
  .count-reservation {
    margin-left: 0 !important;
  }
  .modal-mb {
    margin-bottom: calc(100vh - 150%);
  }
  .d-block-content {
    display: block;
  }

  .column-1 {
    padding-left: 30px;
    width: 110px;
  }
  .column-2 {
    width: 60px;
  }
  .column-3 {
    width: 85px;
    margin-left: 20px;
  }
  .column-4 {
    margin-left: 20px;
    width: 70px;
  }
}

.height-29 {
  height: 21px;
}

.unhover:hover {
  opacity: unset !important;
}

.accordion {
  color: var(--color-text);
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  background: #fff;
  padding-bottom: 0;

  &.active {
    border-bottom: none;
  }
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  background-color: var(--color-background);
  padding: 20px;
}

.info_adjust-item {
  padding: 18px 18px;
  padding-right: 18px;
  overflow: hidden;
}

button.accordion .icon-acc {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
  margin-top: 8px;
}

button.accordion.active .icon-acc {
  position: relative;
  margin-top: 17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
}

.header-res {
  display: flex;
  border-bottom: 1px solid var(--color-background);
  background: var(--main_opacity20);
  color: var(--color-main);

  .col-res {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px 0px 10px 20px;
  }
  .col-res-1-2 {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px 0px 10px 80px;
  }
  .col-res-2-1 {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1 1;
    padding: 10px 0px 10px 30px;
    justify-content: center;
  }


}

.border-bottom-res {
  width: 100%;
  border-bottom: 1px solid #DEE2E6;
}

.w-30-res {
  width: 50% !important;
}

.w-27-res {
  width: 27% !important;
}

.mr-0 {
  margin-right: 0 !important;
}


.item-container {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.single_line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size:16px;
}

.first_span {
  flex: 1 1 auto;
}

.second_span {
  flex: 0 0 auto;
  font-size: 16px;
}

.form_select_table {
  --vs-font-size: 0;
  --vs-actions-padding: 8px 0px 8px 0px;
  --vs-controls-size: 0;
}

.form-modal-reservation {
  --vs-border-color: #DDDDDD;
}

.modal-content {
  border: none;
}

.tab {
  border-bottom: 2px solid var(--color-tt-block-bg);
  color: var(--color-tt-block-bg);
}


.first_span {
  flex: none;
}

.select-with-label-inside .form-control {
  color: var(--color-metal-grey);
  padding-left: 35%;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 24px;
}

.select-with-label-inside {
  padding: 0px;
  position: relative;
}

.label-inside-select {
  position: absolute;
  font-size:16px;
  top: 10px;
  left: 7%;
}

.single-select-label {
  left: 3%;
}

.single-select .form-control {
  color: var(--color-text);
  padding-left: 2%;
}

.reservation-modal {
  width: 100%;
}

.form-modal-reservation {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 10px 20px;
  overflow-y: auto;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 0px;
  margin-right: 0px;
  gap: 20px;
}

span {
  color: var(--color-metal-grey);
  font-size: 16px;
}

.form_select_table {
  width: 100%;
  position: relative;
}

.select-with-label-row .select-with-label-inside {
  width: 50%;
}

.tab_area {
  display: flex;
  gap: 20px;
  width: 100%;
}

.without-label .form-control {
  padding-left: 10%;
}

.vs--searchable {
  width: 100%;
}

textarea.form-control{
  max-height: 120px !important;
  border-width: 2px;
}

.search-div .form-control {
  width: 100%;
}

.heading {
  background-color: var(--color-text-reservation);
  display: flex;
  align-items: center;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  gap: 440px;
}

.cross-button {
  font-weight: normal;
  color: var(--color-white);
  margin-right: 2px;
  cursor: pointer;
}

.modal-body {
  margin-top: 70px;
}

.save-button {
  justify-content: center;
}

.date-time-change-div {
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}

.number-of-person-div {
  display: flex;
  gap: 10%;
}

.form-modal-reservation {
  width: 100%;
}
.form-modal-reservation .form-control{
  color: var(--color-table-name);
}

.content-res {
  padding-right: 20px;
  background-color: var(--color-white);
  color: var(--color-text);

  .col-res {
    //padding-left: 35px;
    padding-right: 20px;
  }

  .left-padded {
    display: flex;
    justify-content: center;
  }

}

.col-res-2 {
  width: 25% !important;
  margin-left: 1.4rem;
}

.accordion-content {
  padding-top: 15px;
  display: flex;
}
.title {
  font-size: 24px !important;
  font-weight: bold;
  color: var(--color-white);
  margin-left: -18px;
}

.margin-top-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
}

span {
  font-size: 24px;
  line-height: 24px;
}
.read-only-form-button{
  width:100%;
}
.modal-content {
  width: 100%;
}
.tab-div {
  width: 100%;
}
.tab_area {
  width: 100%;
  border-bottom: 2px solid var(--color-reserved-border);
  margin-bottom: 0px !important;
}

/* css for mobile */
@media only screen and (max-device-width: 414px) {
  .heading {
    gap: 250px;
  }
  .customer_select {
    padding-left: 2% !important;
  }
  .select-with-label-inside .form-control {
    padding-left: 2%;
  }
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.tag-main-container {
  width: 49%;
  background: #dae8fc;
  border-color: #6c8ebf;
}
.tag-icon {
  background: var(--color-white);
}
label{
  font-size: 20px;
}
textarea.reservation-email {
  background-color: var(--color-white);
  max-height: 550px !important;
  line-height: 1.4rem;
  font-size: 14px;
}
input.reservation-email {
  background-color: var(--color-white);
}
.reservation-email {
  font-size: 22px;
  font-weight: 400;
}
.content-div{
  overflow-y:scroll;
  height:100%;
}
.content-and-footer-container{
  display:flex;
  flex-direction:column;
  height:calc(100dvh - 128px);
  position:relative;
}
.modal-footer{
  justify-content: space-between;
  clear: both;
  .btn {
    float:right;
  }
}
.tag-svg-text{
  position: relative;
  font-size: 16px !important;
  text-align: center;
}
.tag-icon-text{
  position: relative;
  bottom: 6px;
}
.tag-images{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
reservation-modal {
  width: 100%;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 0px;
  margin-right: 0px;
  gap: 20px;
  margin-bottom: 16px;
}
.form-modal-reservation .row {
  margin-bottom: 0px;
  margin-top: 10px;
}
.form-modal-reservation {
  label{
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }
}
.form-modal-reservation label {
  margin-bottom: 0.5rem;
  color: var(--color-table-name);
}
.row-item{
  display: flex;
  gap: 10px;
  align-items: center;
}
.store{
  cursor: pointer;
  background: var(--sub-color-opacity10);
  padding: 8px 35px;
  border-radius: 5px;
  color: var(--color-main);
  font-weight: bold;
}
.status{
  color: var(--color-link-status);
  cursor: pointer;
}
.sub-time{
  padding: 4px;
  background: var(--color-sub-time);
  border: 1px solid var(--color-sub-time);
  font-size: 14px;
  min-width: 20%;
  text-align: center;
  border-radius: 6px;
  height:100%
}
.add-time{
  padding: 4px;
  background: var(--color-add-time);
  border: 1px solid var(--color-add-time-border);
  font-size: 14px;
  min-width: 20%;
  text-align: center;
  border-radius: 6px;
  height:100%
}
.stay-time{
  padding: 4px;
  background: var(--color-stay-time);
  min-width: 25%;
  font-size: 14px;
  text-align: center;
  height:100%
}
.undo-btn{
  padding: 4px;
  background: white;
  border: 1px solid grey;
  min-width: 20%;
  height:100%
}
.input-field-font,.cursor-pointer{
  font-size: 16px !important;
}
.without-label{
  margin-bottom: 10px !important;
}
.reservation-edit-form {
  margin-bottom: 22px;
}
.memo-area{
  max-height: 120px !important;
  min-height: 100px;
  overflow: hidden;
}
.vs__search{
  font-size: 16px !important;
}
.vs__selected{
  font-size: 16px !important;
  text-align: center;
  padding: 6px
}
.vs__selected-options{
  font-size: 16px !important;
}
.seating {
  background: var(--color-sit-down-opacity);
  border: 2px solid var(--color-seating-border);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
}
.reserved {
  background: var( --color-reserved-opacity);
  border: 2px solid var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-reserved-border)
}
.finished{
  background: var(--color-accounted-for);
  border: 2px solid var(--color-medium-grey);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-medium-grey);
}
.cancelled{
  background: var(--gray);
  border: 2px solid var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.not_show {
  background: var(--color-error);
  border: 2px solid var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.error-message {
  color: var(--color-error);
  margn-top: 10px;
}
// .btn-primary{
//   background-color: var(--color-main) !important;
//   border-color: var(--color-main) !important;
// }
</style>
