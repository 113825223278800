<template>
<div>
  <div v-if="errors != ''" class='drag-error' >
    <p>{{errors}}</p>
  </div>
  <div class="timetable-main-container">
    <div class="timetable" :style="`--colW: ${colW}; --cellW: ${colW/4}; --headerH: ${headerH}`">
      <div class="label-wrapper">
        <!-- table header -->
        <div class="table-header-first" :style="{ height: headerH, lineHeight: headerH+'px', top: headerTop, backgroundColor: 'var(--color-text-reservation)'}">
          <div class="shop-table-name text-while" :style="{color: 'var(--color-white'}">テーブル名</div>
        </div>
        <div :style="{ height: headerH}"></div>
        <!-- table list -->
        <div v-for="table, i in tables" :key="i" id="shop-table-name" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
          <div class="shop-table-name" style="cursor: pointer;" @click="openReservationBlockModal(table)">
            <span class="table-name">{{table.name}}</span> <span class="table-no">{{table.number_of_people_min && table.number_of_people_min}} - {{table.number_of_people_max && table.number_of_people_max}} 名席</span>
          </div>
        </div>
        <div class="scrollbar-space"></div>
      </div>

      <div class="data-wrapper" ref="dataWrapper" id="data-wrapper">
        <div class="scroll-wrapper">
          <!-- hour header -->
          <div class="table-header" :style="{ height: headerH, lineHeight: headerH+'px', top: headerTop }">
            <div v-for="hour, i in timeTableHours" :key="i" class="hour">{{hour%24}}:00</div>
          </div>
          <div :style="{ height: headerH}"></div>

          <!-- hour cells -->
          <div v-for="table, iTable in tables" :key="iTable" class="table-row" :style="{ height: rowH + rowH*(table.overlapLevel || 0) }">
            <template v-for="hour, i in timeTableHours">
            <div v-for="minute in [0, 15, 30, 45]" :key="i + minute / 60"
                 @drop="handleDrop"
                 :data-index="hour + ':' + String(minute).padStart(2, '0')" :data-id="table.id" :data-info="table.name"
                 @dragover="handleDragOver" @dragend="handleDragEnd" @dragenter="handleDragEnter($event, 'free')"
                 @click="checkReservationType(table, hour, minute)"
                 :class="[cellClass(hour, minute), {'busy': isBlockedTime(hour, minute, timeTable[iTable].reservations)}]">
            </div>
            </template>
          </div>
          <div class="current-time-line" :style="{ left: nowX }"></div>

          <!-- reservations -->
          <div>
            <template v-for="table, i in tables">
              <template v-for="r in timeTable[i].reservations" >
                <div v-if="r.state != 'busy'">
                    <div @drop="handleTableDrop" :draggable="r.state === 'busy' ? false : true" :data-index="r.id+','+table.id" :data-info="table.name" data-role="dragTable" @dragstart="handleRowDragStart"
                         @dragenter="handleDragEnter($event, r.state)" @dragend="handleDragEnd" @dragover="handleDragOver" class="reservation opacity-unset reservation-main-container"
                         v-show="!r.hide && getWidth(r.start, r.stay_minutes) > 0"
                         :class="[ r.overlap ? 'overlap resizablediv'+r.id : '',r.state + ' resizablediv'+r.id] "
                         :key="`${i}.${r.id}`"
                         :style="{
                height: rowH - 2 + rowH*(r.expandLevel || 0),
                top: headerH + rowH*table.offsetY + rowH*(r.overlapLevel || 0) + 1 - rowH*(r.expandLevel || 0),
                width: getWidth(r.start, r.stay_minutes),
                left: getX(r.start),
              }"
                         :data-id="r.id"
                    >
                      <div class="reservation-container" v-if="r.state != 'busy'">
                       <div @click="openColorModel(r.id,r.background_color)" class="color-label"
                      :style="{backgroundColor:r.background_color,
                      borderColor:(r.state==='reserved'?'var(--color-main)':r.state==='not_show'?'var(--color-error)':'var(--color-white)')}">
                       </div>
                        <ReservationColorSelect
                            :showReservationColor = "showReservationColor && (r.id === reservation_id)"
                            :reservationId="reservation_id"
                            :backGroundColor="backgroundColor"
                            @close="showReservationColor = false"
                            :pop-left="popOverLeft"
                            @reload="$emit('reload');"
                        />
                       <div class="reservation-content" :class="r.state"  @click="r.state == 'busy' ? newReservation(table, null, null, is_block = true) : checkClick($event,r)" >
                         <template v-if="r.state != 'busy'">

                           <div class="table-merge-icon">
                             <i v-for="property in r.customer.properties" class="property-icon" v-if="property.name ==='VIP' || property.name ==='要注意'" :class="[ property.name ==='VIP' ? 'fa fa-star property-icon':'fa fa-exclamation-circle property-icon' ]" aria-hidden="true"></i>
                             <img class="my-primary" v-if="r.is_table_merged" src="../../../../../assets/images/icon_timetable/icon_link.svg" alt="img">
                             <img class="my-bell" v-if="hasNotice(r.id)" src="../../../../../assets/images/icon_timetable/icon_bell.svg" alt="img">
                             <img class="my-fork" v-if="r.course_id!=null"  src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
                             <img class="my-message" v-if="r.memo_from_customer || r.memo" src="../../../../../assets/images/icon_timetable/icon_message.svg" alt="img">
                           </div>
                           <template v-if="r.stay_minutes >= 30">
                             <div class="d-flex name">
                               <div class="full-name"
                                    :class="{ 'merge-table': r.customer.count_reservation }">
                                 {{`${r.last_name} ${r.first_name} 様` }}
                              </div>
                              <div v-if="r.customer.count_reservation">
                                <div class="square-number-test" v-if="r.customer.count_reservation == 1">初</div>
                                <div class="square-number-test" v-else-if="r.customer.count_reservation <= 9">{{r.customer.count_reservation}}</div>
                                <div class="square-number-test" v-else>多</div>
                              </div>
                            </div>
                             <div class=" number-of-people">{{r.number_of_people}}名</div>
                             <div v-if="r.course_name" class="course-name">{{ r.course_name }}</div>
                             <div class="type">{{r.type_label}}</div>

                             <div class="tag" v-if="getWidth(r.start, r.stay_minutes) < 300">
                               <template v-if="r.tag_details && r.tag_details.filter(tag => tag.icon).length === 0">
                                <span style="font-size: 12px; font-weight: bold" v-if="r.tag_details.length > 0">
                                  他{{ r.tag_details.length }}
                                </span>
                               </template>
                               <template v-else-if="r.tag_details" >
                                  <span v-for="(tag, index) in r.tag_details" :key="index">
                                    <template v-if="index === 0 && tag.icon">
                                      <svg v-if="tag.icon === 'crown'" xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_400" data-name="グループ 400" transform="translate(530 -3683)">
                                         <path id="パス_114" data-name="パス 114" d="M7.211,32.291a7.113,7.113,0,0,0,.737-.074l3.8,42.791H128.257l3.8-42.791a7.118,7.118,0,0,0,.736.074,7.221,7.221,0,1,0-5.068-2.087l-26.3,26.3L72.628,13.911a7.212,7.212,0,1,0-5.256,0L38.577,56.5l-26.3-26.3a7.207,7.207,0,1,0-5.068,2.087" transform="translate(-520 3712)" fill="#1a1311"/>
                                         <path id="パス_115" data-name="パス 115" d="M24.675,166.285l1.485,16.742H137.886l1.485-16.742Z" transform="translate(-532.023 3630.978)" fill="#1a1311"/>
                                         <rect id="長方形_653" data-name="長方形 653" width="160" height="160" transform="translate(-530 3683)" fill="none"/>
                                        </g>
                                      </svg>
                                      <svg v-else-if="tag.icon === 'coupon'" xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_402" data-name="グループ 402" transform="translate(733 -3404)">
                                         <path id="前面オブジェクトで型抜き_1" data-name="前面オブジェクトで型抜き 1" d="M128.175,81.452H11.824A11.825,11.825,0,0,1,0,69.629V52.98a12.4,12.4,0,0,0,7.564-4.108,12.356,12.356,0,0,0,3.061-8.145,12.357,12.357,0,0,0-3.061-8.146A12.41,12.41,0,0,0,0,28.472V11.825A11.824,11.824,0,0,1,11.824,0H128.175A11.825,11.825,0,0,1,140,11.825V28.361a12.378,12.378,0,0,0,0,24.729V69.629a11.825,11.825,0,0,1-11.824,11.823ZM29.159,63.381v10.61h5.068V63.381Zm30.6-38.735a12.682,12.682,0,0,0-5.37,1.138,11.923,11.923,0,0,0-4.17,3.227c-2.395,2.874-3.66,6.884-3.66,11.6,0,4.562,1.2,8.45,3.475,11.244a11.613,11.613,0,0,0,9.325,4.315,12.082,12.082,0,0,0,9.37-4.03c2.48-2.816,3.79-6.969,3.79-12.01a21.252,21.252,0,0,0-.852-6.131,14.818,14.818,0,0,0-2.483-4.909A11.545,11.545,0,0,0,59.76,24.646Zm38.64.481v30.6h4.64V42.286h10.48V38.446H103.04v-9.44h11.28v-3.88Zm-21.119,0v30.6H81.92V42.286H92.4V38.446H81.92v-9.44H93.2v-3.88ZM29.159,44.741v10.61h5.068V44.741Zm0-18.643V36.711h5.068V26.1Zm0-18.637V18.07h5.068V7.461Zm30.361,44.9A6.924,6.924,0,0,1,53.5,48.751a15.929,15.929,0,0,1-2.06-8.265c0-5.993,2.523-12.04,8.159-12.04a6.9,6.9,0,0,1,6.075,3.73,16.135,16.135,0,0,1,1.965,8.11,16.457,16.457,0,0,1-2.05,8.485A6.936,6.936,0,0,1,59.52,52.366Z" transform="translate(-724 3443)" fill="#1a1311"/>
                                         <rect id="長方形_655" data-name="長方形 655" width="160" height="160" transform="translate(-733 3404)" fill="none"/>
                                        </g>
                                      </svg>
                                      <svg v-else-if="tag.icon === 'cheers'" xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_401" data-name="グループ 401" transform="translate(447 -3404)">
                                         <path id="パス_116" data-name="パス 116" d="M60.682,61,23.425,51.782a3.883,3.883,0,0,0-4.707,2.841l-13.1,52.925a23.064,23.064,0,0,0,13.165,26.67l-6.662,26.924-7.3-1.808a3.89,3.89,0,0,0-1.869,7.552l22.159,5.483a3.888,3.888,0,0,0,1.869-7.548l-7.309-1.81,6.663-26.926a23.067,23.067,0,0,0,24.09-17.45l13.1-52.926A3.889,3.889,0,0,0,60.682,61M50.371,86.488,20.664,79.137l4.669-18.871,29.707,7.349Z" transform="translate(-435.755 3381.518)" fill="#1a1311"/>
                                         <path id="パス_117" data-name="パス 117" d="M262.463,162.176a3.891,3.891,0,0,0-4.709-2.843l-7.3,1.808-6.661-26.924a23.067,23.067,0,0,0,13.167-26.67l-13.1-52.925a3.883,3.883,0,0,0-4.707-2.841L201.9,61a3.889,3.889,0,0,0-2.841,4.709l13.1,52.926a23.067,23.067,0,0,0,24.09,17.45L242.9,163.01l-7.309,1.81a3.888,3.888,0,0,0,1.869,7.548l22.159-5.483a3.889,3.889,0,0,0,2.839-4.709M212.207,86.488l-4.669-18.873,29.707-7.349,4.669,18.871Z" transform="translate(-560.823 3381.518)" fill="#1a1311"/>
                                         <path id="パス_118" data-name="パス 118" d="M180.219,0A2.917,2.917,0,0,0,177.3,2.916V9.234a2.916,2.916,0,1,0,5.833,0V2.916A2.917,2.917,0,0,0,180.219,0" transform="translate(-547.219 3414)" fill="#1a1311"/>
                                         <path id="パス_119" data-name="パス 119" d="M125.994,21.64a2.916,2.916,0,1,0,4.671-3.492l-4.322-5.78a2.917,2.917,0,0,0-4.671,3.494Z" transform="translate(-511.881 3406.96)" fill="#1a1311"/>
                                         <path id="パス_120" data-name="パス 120" d="M227.127,21.64l4.322-5.779a2.916,2.916,0,0,0-4.671-3.494l-4.322,5.78a2.916,2.916,0,1,0,4.671,3.492" transform="translate(-575.24 3406.96)" fill="#1a1311"/>
                                         <rect id="長方形_654" data-name="長方形 654" width="160" height="160" transform="translate(-447 3404)" fill="none"/>
                                        </g>
                                      </svg>
                                      <i v-else :class="[tag.icon, 'pe-auto', 'tag-icon']" aria-hidden="true"></i>
                                    </template>
                                    <template v-else-if="index === 1 && r.tag_details.length > 1">
                                      <span style="font-size: 12px; font-weight: bold">
                                        他 {{ r.tag_details.length - 1 }}
                                      </span>
                                    </template>
                                  </span>
                               </template>
                             </div>
                             <div class="tag" v-else>
                               <template v-if="r.tag_details && r.tag_details.filter(tag => tag.icon) && !r.tag_details.some(tag => tag.icon)">
                                  <span v-if="r.tag_details.length > 0" style="font-size: 12px; font-weight: bold">
                                    他{{ r.tag_details.length }}
                                  </span>
                                   </template>
                                  <template v-if="r.tag_details">
                                  <span v-for="(tag, index) in r.tag_details.filter(tag => tag.icon)" :key="`${tag.icon}-${index}`">
                                    <template v-if="tag.icon === 'crown'">
                                      <svg  xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_400" data-name="グループ 400" transform="translate(530 -3683)">
                                         <path id="パス_114" data-name="パス 114" d="M7.211,32.291a7.113,7.113,0,0,0,.737-.074l3.8,42.791H128.257l3.8-42.791a7.118,7.118,0,0,0,.736.074,7.221,7.221,0,1,0-5.068-2.087l-26.3,26.3L72.628,13.911a7.212,7.212,0,1,0-5.256,0L38.577,56.5l-26.3-26.3a7.207,7.207,0,1,0-5.068,2.087" transform="translate(-520 3712)" fill="#1a1311"/>
                                         <path id="パス_115" data-name="パス 115" d="M24.675,166.285l1.485,16.742H137.886l1.485-16.742Z" transform="translate(-532.023 3630.978)" fill="#1a1311"/>
                                         <rect id="長方形_653" data-name="長方形 653" width="160" height="160" transform="translate(-530 3683)" fill="none"/>
                                        </g>
                                      </svg>
                                    </template>
                                    <template v-else-if="tag.icon === 'coupon'">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_402" data-name="グループ 402" transform="translate(733 -3404)">
                                         <path id="前面オブジェクトで型抜き_1" data-name="前面オブジェクトで型抜き 1" d="M128.175,81.452H11.824A11.825,11.825,0,0,1,0,69.629V52.98a12.4,12.4,0,0,0,7.564-4.108,12.356,12.356,0,0,0,3.061-8.145,12.357,12.357,0,0,0-3.061-8.146A12.41,12.41,0,0,0,0,28.472V11.825A11.824,11.824,0,0,1,11.824,0H128.175A11.825,11.825,0,0,1,140,11.825V28.361a12.378,12.378,0,0,0,0,24.729V69.629a11.825,11.825,0,0,1-11.824,11.823ZM29.159,63.381v10.61h5.068V63.381Zm30.6-38.735a12.682,12.682,0,0,0-5.37,1.138,11.923,11.923,0,0,0-4.17,3.227c-2.395,2.874-3.66,6.884-3.66,11.6,0,4.562,1.2,8.45,3.475,11.244a11.613,11.613,0,0,0,9.325,4.315,12.082,12.082,0,0,0,9.37-4.03c2.48-2.816,3.79-6.969,3.79-12.01a21.252,21.252,0,0,0-.852-6.131,14.818,14.818,0,0,0-2.483-4.909A11.545,11.545,0,0,0,59.76,24.646Zm38.64.481v30.6h4.64V42.286h10.48V38.446H103.04v-9.44h11.28v-3.88Zm-21.119,0v30.6H81.92V42.286H92.4V38.446H81.92v-9.44H93.2v-3.88ZM29.159,44.741v10.61h5.068V44.741Zm0-18.643V36.711h5.068V26.1Zm0-18.637V18.07h5.068V7.461Zm30.361,44.9A6.924,6.924,0,0,1,53.5,48.751a15.929,15.929,0,0,1-2.06-8.265c0-5.993,2.523-12.04,8.159-12.04a6.9,6.9,0,0,1,6.075,3.73,16.135,16.135,0,0,1,1.965,8.11,16.457,16.457,0,0,1-2.05,8.485A6.936,6.936,0,0,1,59.52,52.366Z" transform="translate(-724 3443)" fill="#1a1311"/>
                                         <rect id="長方形_655" data-name="長方形 655" width="160" height="160" transform="translate(-733 3404)" fill="none"/>
                                        </g>
                                      </svg>
                                    </template>
                                    <template v-else-if="tag.icon === 'cheers'">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" class="tag-svg">
                                        <g id="グループ_401" data-name="グループ 401" transform="translate(447 -3404)">
                                         <path id="パス_116" data-name="パス 116" d="M60.682,61,23.425,51.782a3.883,3.883,0,0,0-4.707,2.841l-13.1,52.925a23.064,23.064,0,0,0,13.165,26.67l-6.662,26.924-7.3-1.808a3.89,3.89,0,0,0-1.869,7.552l22.159,5.483a3.888,3.888,0,0,0,1.869-7.548l-7.309-1.81,6.663-26.926a23.067,23.067,0,0,0,24.09-17.45l13.1-52.926A3.889,3.889,0,0,0,60.682,61M50.371,86.488,20.664,79.137l4.669-18.871,29.707,7.349Z" transform="translate(-435.755 3381.518)" fill="#1a1311"/>
                                         <path id="パス_117" data-name="パス 117" d="M262.463,162.176a3.891,3.891,0,0,0-4.709-2.843l-7.3,1.808-6.661-26.924a23.067,23.067,0,0,0,13.167-26.67l-13.1-52.925a3.883,3.883,0,0,0-4.707-2.841L201.9,61a3.889,3.889,0,0,0-2.841,4.709l13.1,52.926a23.067,23.067,0,0,0,24.09,17.45L242.9,163.01l-7.309,1.81a3.888,3.888,0,0,0,1.869,7.548l22.159-5.483a3.889,3.889,0,0,0,2.839-4.709M212.207,86.488l-4.669-18.873,29.707-7.349,4.669,18.871Z" transform="translate(-560.823 3381.518)" fill="#1a1311"/>
                                         <path id="パス_118" data-name="パス 118" d="M180.219,0A2.917,2.917,0,0,0,177.3,2.916V9.234a2.916,2.916,0,1,0,5.833,0V2.916A2.917,2.917,0,0,0,180.219,0" transform="translate(-547.219 3414)" fill="#1a1311"/>
                                         <path id="パス_119" data-name="パス 119" d="M125.994,21.64a2.916,2.916,0,1,0,4.671-3.492l-4.322-5.78a2.917,2.917,0,0,0-4.671,3.494Z" transform="translate(-511.881 3406.96)" fill="#1a1311"/>
                                         <path id="パス_120" data-name="パス 120" d="M227.127,21.64l4.322-5.779a2.916,2.916,0,0,0-4.671-3.494l-4.322,5.78a2.916,2.916,0,1,0,4.671,3.492" transform="translate(-575.24 3406.96)" fill="#1a1311"/>
                                         <rect id="長方形_654" data-name="長方形 654" width="160" height="160" transform="translate(-447 3404)" fill="none"/>
                                        </g>
                                      </svg>
                                    </template>
                                    <template v-else>
                                      <i :class="[tag.icon, 'pe-auto', 'tag-icon']" aria-hidden="true"></i>
                                    </template>
                                  </span>
                                    <span v-if="r.tag_details && r.tag_details.filter(tag => !tag.icon).length > 0" style="font-size: 12px; font-weight: bold">他{{ r.tag_details.filter(tag => !tag.icon).length }}</span>
                               </template>
                             </div>
                           </template>
                         </template>
                       </div>
                     </div>
                    </div>
                </div>
                <div :data-index="r.id+','+table.id" data-role="dragTime" :class="'resizabletimediv'+r.id"
                     @dragenter.prevent @dragover.prevent @dragstart="handleDragStart" class="time-expand" :style="{ transition: 'all 0.3s ease',
                height: rowH - 2 + rowH*(r.expandLevel || 0),
                top: headerH + rowH*table.offsetY + rowH*(r.overlapLevel || 0) + 1 - rowH*(r.expandLevel || 0),
                left: getTimeX(r.start,Math.round(r.stay_minutes/2)),
                width: getWidth(r.start, Math.round(r.stay_minutes/2)),
              }">
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <MergeModal
          v-show="showMergeModal"
          :reservation="mergeReservation"
          @close="showMergeModal = false"
          @loading="updateLoading"
          @reload="$emit('reload')"
      >
      </MergeModal>
      <ChooseReservationTypeModal
          :is-visible="showChooseModal"
          :selected-date="date"
          @close="showChooseModal=false"
          @change-show-reservation-modal="changeShowReservationModal" >
      </ChooseReservationTypeModal>
      <Loading v-show="loading" />
    </div>

    <WalkReservationModal
        v-if="showWalkReservationModal"
        @close="showWalkReservationModal = false"
        @reload="$emit('reload')"
        @update-adults="updateAdults"
        @update-children="updateChildren"
        :reservation="editReservation"
    />

    <div class="right-sidebar" :class="{expandedRightSidebar: !showPeoplesEditModal && (showReservationModal ||
                                                                                          showModalReservationBlock ||
                                                                                          showColorSelectModal ||
                                                                                          showPeoplesEditModal)}">

  <ReservationModal
        v-show="showReservationModal"
        :reservation="editReservation"
        :reservations="customerReservations"
        :customerEdit="customerEdit"
        @close="showReservationModal = false"
        :showResverveModal="showReservationModal"
        :showReservationTags="showReservationTags"
        @loading="updateLoading"
        @reload="$emit('reload')"
        @openMergeModal="openMergeModal"
        fromWhere="fromTimeTable"
        @change-reservation="ChangeReservation"
        :insideFifteenMinute="insideFifteenMinute"

    >
    </ReservationModal>

    <ReservationBlockModal
        :reservation="editReservation"
        @reload="$emit('reload')"
        :datetime_blocks="datetime_blocks"
        :working-time="workingTime"
        v-show="showModalReservationBlock"
        @close="showModalReservationBlock = false"/>

    <ColorSelectComponent
        v-if="showColorSelectModal"
        @close="showColorSelectModal=false"
        :reservationId="reservation_id"
        :backGroundColor="backgroundColor"
        @reload="$emit('reload');"
    />
    <ReservationNewModel
        v-show="showNewReservation"
        :reservation="editReservation"
        :customerEdit="customerEdit"
        @show-peoples-modal="showPeoplesModal"
        :showReservationTags="showReservationTags"
        @show-modal="showModal"
        @reload="$emit('reload');"
        @close="showNewReservation = false"
        from="time_table"
        class="new-reservation-modal"
    />
     <EditPeoplesModal
         v-if="showPeoplesEditModal"
         @update:number_of_adults="number_of_adults = $event"
         @update:number_of_children="number_of_children = $event"
         @update:number_of_people="number_of_people = $event"
         @close="showPeoplesEditModal = false"
         @close-with-update="handlePeopleUpdateSuccess"
         :reservation="editReservation"
         @reload="$emit('reload')"
         class="peoples-edit-modal"
     />
    </div>
  </div>
  <div class="time-table-footer">
      <span>
        予約ブロック
      </span>
    <a href="/shop_manager/reservation_block">
        <img src="../../../../../assets/images/icon_timetable/icon_footer1.svg" width="100" height="100" alt="img">
      </a>
    <span>
      新規予約
    </span>
    <span  @click="OpenReservationModal">
      <img src="../../../../../assets/images/icon_timetable/icon_footer2.svg" width="100" height="100" alt="img">
    </span>
    </div>
</div>

</template>

<script>
import moment from 'moment';
import ReservationModal from '../../components/ReservationModal.vue';
import MergeModal from "./MergeModal.vue";
import Http from "../../../shared/http"
import Loading from '../../components/Loading';
import {DisableScrollBody, fixBodyWidth} from "../../../shared/util";
import ReservationBlockModal from '../../components/ReservationBlockModal.vue';
import ColorSelectComponent from '../../components/ColorSelectComponent.vue';
import ChooseReservationTypeModal from "./ChooseReservationTypeModal.vue";
import ReservationNewModel from "./ReservationNewModel.vue";
import StatusChangeModal from "./StatusChangeModal.vue";
import ReservationColorSelect from "./ReservationColorSelect.vue";
import WalkReservationModal from "../../components/WalkReservationModal.vue";
import EditPeoplesModal from "./EditPeoplesModal.vue";

export default {
  components: {
    EditPeoplesModal,
    ReservationColorSelect,
    StatusChangeModal,
    ReservationNewModel,
    ChooseReservationTypeModal,
    ColorSelectComponent, ReservationModal, MergeModal, Loading, ReservationBlockModal, WalkReservationModal},
  props: {
    date: {
      type: Date,
    },
    workingTimes: {
      type: Array,
      default: () => [],
    },
    workStart: {
      type: Object
    },
    workEnd: {
      type: Object
    },
    timeTableStart: {
      type: Object
    },
    timeTableEnd: {
      type: Object
    },
    timeTable: {
      type: Array,
    },
    notices: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    printTimeStart: {
      type: String,
    },
    printTimeEnd: {
      type: String,
    },
  },
  data() {
    return {
      r_type:'',
      reservation_id:null,
      backgroundColor:'',
      showChooseModal:false,
      showColorSelectModal:false,
      timeTableCopy: [...this.timeTable],
      showPeoplesEditModal:false,
      errors:'',
      isDragging: false,
      selectedReservation : null,
      initialWidth :0,
      initialTimeDivWidth:0,
      startX:0,
      startY: 0,
      current_tables :[],
      target_tables : [],
      button_index :'',
      button_role :'',
      current_table_id:0,
      current_reservation_id:0,
      target_reservation_time:0,
      target_table_id:0,
      target_table_name:'',
      staying_minutes:0,
      headerH: 40,
      rowH: 80,
      colW: 120,
      nowX: -10,
      showWalkReservationModal: false,
      showReservationModal: false,
      editReservation: {},
      showReservationTags: [],
      showNewReservationModal:false,
      showNewReservation: false,
      showMergeModal: false,
      showReservationColor:false,
      mergeReservation: {},
      headerTop: 0,
      customerEdit: {},
      customerReservations: [],
      course: {},
      showModalReservationBlock: false,
      reservationBlocks: [],
      datetime_blocks: [],
      workingTime: {},
      initialPosition:{x:0,y:0},
      finalPosition:{x:0,y:0},
      initialScrollLeft :0,
      popOverLeft: 0,
      number_of_adults: this.number_of_adults,
      number_of_children: this.number_of_children,
      number_of_people: this.number_of_people,
      insideFifteenMinute:false
    };
  },
  computed: {
    timeTableHours() {
      try{
        if (this.workingTimes.length < 1) return []
        let hours = Math.round(moment.duration(this.timeTableEnd.diff(this.timeTableStart)).asHours())
        const startHour = this.timeTableStart.hour()
        return new Array(hours).fill(0).map((_, i) => startHour + i)
      }
      catch{
        this.reloadComponent()
      }
    },
    tables() {
      return this.timeTable.map(t => t.table)
    },
  },
  created () {
    this.timerNowX = setInterval(this.updateNowX, 1000 * 60 * 5)
    this.timerAutoReload = setInterval(this.autoReload, 1000 * 60 * 15)
    window.addEventListener('scroll', this.handleScroll)
    this.resetData(true)
  },
  beforeDestroy () {
    clearInterval(this.timerNowX);
    clearInterval(this.timerAutoReload);
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {

    workingTimes() {
      this.updateNowX()
    },
    showReservationModal(newVal) {
      DisableScrollBody(newVal)
      fixBodyWidth(newVal)


      return newVal;
    },
    showMergeModal(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    },
  },
  methods: {
    OpenReservationModal(){
      window.location.href = `/shop_manager/time_table/reservations/1?date=${this.formatDate(this.date)}`
    },
    formatDate(date){
      if(date==='' || date===null || date===undefined){
        return moment(new Date).format('YYYY-MM-DD')

      }
      else{
        return moment(this.date).format('YYYY-MM-DD')
      }

    },
    openColorModel(id,color){

        const scrollDemo = document.querySelector("#data-wrapper");
      // initilizing table warpper scroll pos
      // this.initialScrollLeft = scrollDemo.scrollLeft
      if((event.clientX + 352) <scrollDemo.getBoundingClientRect().right){
        this.popOverLeft = 0
      }
      else{
        this.popOverLeft = event.clientX + 352 - scrollDemo.getBoundingClientRect().right
      }
      this.showReservationModal=false;
      this.showWalkReservationModal = false;
      this.showModalReservationBlock=false;
      this.showColorSelectModal=false;
      this.showReservationColor = true;
      this.reservation_id=id;
      this.backgroundColor=color;
    },
    checkReservationType(table, hour, minute) {
      const currentTime = moment();
      const currentMinute = currentTime.minute();

      let walkInStartTime, walkInEndTime;
      if (currentMinute < 15) {
        walkInStartTime = currentTime.clone().subtract(1, 'hour').minute(45)
        walkInEndTime = currentTime.clone().minute(15)
      } else if (currentMinute < 30) {
        walkInStartTime = currentTime.clone().minute(0)
        walkInEndTime = currentTime.clone().minute(30)
      } else if (currentMinute < 45) {
        walkInStartTime = currentTime.clone().minute(15)
        walkInEndTime = currentTime.clone().minute(45)
      } else {
        walkInStartTime = currentTime.clone().minute(30)
        walkInEndTime = currentTime.clone().add(1, 'hour').minute(0)
      }

      const reservationTime = moment().hour(hour).minute(minute)
      if (reservationTime.isSameOrAfter(walkInStartTime) && reservationTime.isSameOrBefore(walkInEndTime)) {
        this.walkReservation(table, hour, minute);
        this.insideFifteenMinute=true
      } else {
        this.newReservation(table, hour, minute);
        this.insideFifteenMinute= false
      }
    },
    //reset data after cancel
    resetData(edited){
      if(edited) {
        if (this.initialWidth > 0) {
          const divElements = document.getElementsByClassName('resizablediv' + this.selectedReservation); // expanding width of the all related reservations
          for (let i = 0; i < divElements.length; i++) {
            const currentElement = divElements[i];
            currentElement.style.width = this.initialWidth + 'px'; // Set the width to your desired value
          }
          const timeDivElements = document.getElementsByClassName('resizabletimediv' + this.selectedReservation);// pushing dragging element to left
          for (let i = 0; i < timeDivElements.length; i++) {
            const currentElement = timeDivElements[i];
            currentElement.style.width = this.initialTimeDivWidth + 'px'; // Set the width to your desired value
          }
        }
      }
    const elements = document.querySelectorAll('.custom-div');
    elements.forEach(function(element) {
      element.parentNode.removeChild(element);
    });
      document.removeEventListener('mousedown', this.startDrag);
      document.removeEventListener('touchstart', this.startDrag);
      this.button_role = ''
      this.button_index = ''
      this.initialPosition = {x: 0, y: 0}
      this.finalPosition = {x: 0, y: 0}
      this.staying_minutes = 0
      this.startY = 0
      this.startX=0
      this.timer = 0
      this.counter = 0
      this.isDragging=false
      this.initialWidth = 0
      this.initialTimeDivWidth = 0
      this.initialScrollLeft = 0
      const boxes = document.querySelectorAll(".resizabletimediv"+this.selectedReservation);
      boxes.forEach(box => {
        box.style.display = 'none';
      });

      this.selectedReservation = ''
      $('.reservation').removeClass('active')

    },
    reloadComponent() {
      this.resetData(false)
      this.$emit('reload');
    },
    showModal(reservation){
      this.openReservation(reservation, 'sideModal')
    },

    showPeoplesModal(reservation){
      this.openReservation(reservation, 'peoples-edit-modal')
    },

    scrollLeft() {
      data = document.getElementById('data-wrapper');
      data.scrollTo(0, 0);
    },
    changeShowReservationModal(res_type){
      this.r_type = res_type
      this.showNewReservationModal = !this.showNewReservationModal
    },
    closeReservationModal(){
      this.r_type=''
      this.showNewReservationModal=false
    },
    handleDragEnter(event, state) {
      event.preventDefault();
      const elements = document.querySelectorAll('.custom-div');
      elements.forEach(function (element) {
        element.parentNode.removeChild(element);
      });
      let parentNode = document.getElementsByClassName("scroll-wrapper");
        const x = this.checkdevice() ? event.clientX : (event.clientX);
        const y = event.clientY;
        const ele = document.elementsFromPoint(x, y);
        const ele2 = ele.find(element => element.classList.contains("dropzone"));
        const selected = document.getElementsByClassName('resizablediv' + this.current_reservation_id)
        let newDiv = document.createElement("div");
        newDiv.style.width = selected[0].getBoundingClientRect().width;
        newDiv.style.height = selected[0].getBoundingClientRect().height;
        if (ele2) {
          newDiv.style.top = ele2.getBoundingClientRect().top - parentNode[0].getBoundingClientRect().top;
          newDiv.style.left = ele2.getBoundingClientRect().left - parentNode[0].getBoundingClientRect().left;
          newDiv.style.position = 'absolute';
          newDiv.style.opacity = '0.7';
          newDiv.style.zIndex = '10';
          newDiv.style.background = 'var(--color-tt-course)'
          newDiv.classList.add("custom-div"); // Add a class for additional styles
        }
    const role = this.button_role
    if(state === 'busy' || state === 'free') {
      // adding element for dragging to know where it element will locate
      if (role !== 'dragTime') {
        const reservation_element = ele.filter(element => element.classList.contains("reservation"));
        if (reservation_element.length > 0) {
          const reservation_block_element = reservation_element.filter(element => !element.classList.contains("busy"));
          // checking if it is reservation block or other
          if (reservation_block_element.length <= 0) {
            newDiv.addEventListener('drop', this.handleDrop, {passive: false})
            if (ele2) {
              ele2.appendChild(newDiv);
            }
          }
        } else {
          newDiv.addEventListener('drop', this.handleDrop, {passive: false})
          if (ele2) {
            ele2.appendChild(newDiv);
          }
        }
        }
      }
      else {
        let toIndexElement = event.target.closest('[data-index]');
        this.target_table_name = event.target.closest('[data-info]');
        let target_table_details = toIndexElement.getAttribute('data-index')
        this.target_reservation  = parseInt(target_table_details.split(',')[0]);
        if(this.target_reservation === this.current_reservation_id){
          newDiv.addEventListener('drop', this.handleDrop, {passive: false})
          if (ele2) {
            ele2.appendChild(newDiv);
          }
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleRowDragStart(event) {
      event.dataTransfer.setData("text/plain", event.target.dataset.index);
      const div_name = 'resizablediv' + event.target.dataset.index.split(',')[0]
      const ele = document.getElementsByClassName(div_name)[0]
      this.button_role = event.target.dataset.role;
      this.current_reservation_id = parseInt(event.target.dataset.index.split(',')[0]);
      this.current_table_id = parseInt(event.target.dataset.index.split(',')[1]);
      event.dataTransfer.effectAllowed = "move";
      this.startX = event.clientX;
      const div_length = document.getElementsByClassName(div_name).length
      let sectionRects = [...document.getElementsByClassName(div_name)]
          .map(el => el.getBoundingClientRect());
      let rect = sectionRects[div_length - 1]
      const top = rect.top;
      const left = rect.left;
      this.initialPosition = {x: event.clientX - left, y: event.clientY - top}
      event.dataTransfer.effectAllowed = "move";
    },
    getHours(date) {
      return moment(date).format('HH')
    },
    removeEvents(){
      document.removeEventListener("mousedown", this.startDrag);
      document.removeEventListener('touchstart', this.startDrag);
      document.removeEventListener('touchmove', this.handleDrag);
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('dragmove', this.handleDrag);
      document.removeEventListener('mouseup', this.handleDrop);
      document.removeEventListener('touchend', this.handleDrop);
      document.removeEventListener('dragstart', this.handleDrop);
      document.removeEventListener('dragend', this.handleDrop);
    },
    // for time expansion start drag

    startDrag(event) {
      event.preventDefault()
      let x = this.checkdevice()? event.changedTouches[0].clientX : event.clientX //event for touch or pointer
      let y = this.checkdevice()? event.changedTouches[0].clientY : event.clientY
      const elements = document.elementsFromPoint(x, y);
      // check if click is on the reservation div
      const ele2 = elements.find(element => element.classList.contains("resizabletimediv"+this.selectedReservation));
      // checking if selected dragging element is current element
      if (ele2===undefined || ele2 === null) {
        event.stopImmediatePropagation()
        this.resetData(true)
        return
      }
      this.isDragging = true;
      let ele = document.getElementsByClassName('resizablediv' + this.selectedReservation)[0]
      // initializing the width of all reservation
      this.initialWidth = parseInt(ele.style.width.replace('px', ''));
      this.initialTimeDivWidth = parseInt(ele2.style.width.replace('px', ''));
      document.addEventListener('touchmove', this.handleDrag, {passive:false});
      document.addEventListener('dragstart', this.handleDrag, {passive:false});
      document.addEventListener('mousemove', this.handleDrag, {passive:false});
      document.addEventListener('dragmove', this.handleDrag, {passive:false});
      document.addEventListener('mouseup', this.handleDrop, {passive:false});
      document.addEventListener('touchend', this.handleDrop, {passive:false});
      document.addEventListener('dragend', this.handleDrop, {passive:false});
      this.startX = this.checkdevice()? event.changedTouches[0].clientX : event.clientX;
      this.startY = this.checkdevice()? event.changedTouches[0].clientY : event.clientY;
      const scrollDemo = document.querySelector("#data-wrapper");
      // initilizing table warpper scroll pos
      this.initialScrollLeft = scrollDemo.scrollLeft
    },
    handleDrag() {
      if (this.isDragging) {
        let data = document.getElementById('data-wrapper');
        const newScrollLeft = data.scrollLeft - this.initialScrollLeft
        const newWidth = this.initialWidth  + ((this.checkdevice()? event.changedTouches[0].clientX : event.clientX) - this.startX);
        const newTimeDiv = this.initialTimeDivWidth  +((this.checkdevice()? event.changedTouches[0].clientX : event.clientX) - this.startX);
        // expanding reservation with the cursor position
        const divElements = document.getElementsByClassName('resizablediv' + this.selectedReservation); // expanding width of the all related reservations
        for (let i = 0; i < divElements.length; i++) {
          const currentElement = divElements[i];
          currentElement.style.width = (newWidth + newScrollLeft) + 'px'; // Set the width to your desired value
        }
        const timeDivElements = document.getElementsByClassName('resizabletimediv' + this.selectedReservation);// pushing dragging element to left
        for (let i = 0; i < timeDivElements.length; i++) {
          const currentElement = timeDivElements[i];
          currentElement.style.width = (newTimeDiv+ newScrollLeft) + 'px'; // Set the width to your desired value
        }
        // get current position on scrolling
        const newx = this.checkdevice()? event.changedTouches[0].clientX : event.clientX
        // scroll to new position after dragging
        data.scrollTo(  newx - this.startX + this.initialScrollLeft, this.startY)
      }
    },
    handleDragStart(event) {
      this.button_index =  event.target.dataset.index;
      this.button_role = event.target.dataset.role;
      // Prevent the default drag behavior to allow custom dragging
      event.preventDefault();
    },
    convertTimeToZeroPadded(timeString) {
      let [hours, minutes] = timeString.split(':');
      // Pad the hour with a leading zero if it's less than 10
      if (hours.length < 2) {
        hours = '0' + hours;
      }
      if (minutes.length < 2) {
        minutes = '0' + minutes;
      }
      // Return the formatted time string
      return `${hours}:${minutes}`;
    },
    handleDragEnd() {
      // Clean up any dragging state
      this.isDragging = false;
      const elements = document.querySelectorAll('.custom-div');
      elements.forEach(function(element) {
        element.parentNode.removeChild(element);
      });
    },
    handleTableDrop(event) { //dropping one reservation to other reservation
      document.querySelectorAll('.memo-popup').forEach((el) => el.remove())
      event.preventDefault();
      this.button_role = '';
      let toIndexElement
      toIndexElement = event.target.closest('[data-index]');
      this.target_table_name = event.target.closest('[data-info]');
      let target_table_details = toIndexElement.getAttribute('data-index')
      this.target_reservation  = parseInt(target_table_details.split(',')[0]);
      this.target_table_id= parseInt(target_table_details.split(',')[1]);
      this.target_table_name =toIndexElement.getAttribute('data-info');
      if(this.current_reservation_id!==this.target_reservation){
        this.check_reservations()
      }
      else{
        const elements = document.elementsFromPoint(event.clientX, event.clientY);
        const ele2 = elements.find(element => element.classList.contains("dropzone"));
        const req_data = this.getReservationIndex(this.current_table_id,this.current_reservation_id)
        const formattedTime = this.convertTimeToZeroPadded(ele2.dataset.index);
        if(formattedTime !==moment(req_data.reservation.start).format("HH:mm")){
          this.button_role = 'dragTable'
          this.handleDrop(event)
        }
      }
    },
    async check_reservations(){
      this.current_tables = []
      this.target_tables = []
      let req_data_table = []
      let target_data_table = []
      const req_data = this.getReservationIndex(this.current_table_id,this.current_reservation_id)
      const target_data = this.getReservationIndex(this.target_table_id,this.target_reservation)
      let req_end_time = ''
      let msg = ''
      let title = ''
      if(req_data.reservation.is_table_merged || target_data.reservation.is_table_merged) { // dragged table is merged reservation
        msg = `テーブルを複数選択している予約情報は<br>ドラッグ&ドロップでテーブル移動できません`;
        title = 'テーブル移動できません';
        let confirm = await this.$swal.fire({
          title: title,
          html: msg,
          customClass: {
            content: 'swal2-content',
            confirmButton: 'btn btn-primary btn-fill btn-unfocus'
          },
          icon: 'warning',
          allowOutsideClick: false,
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
        if (confirm.isConfirmed) {
          this.reloadComponent();
          return
        } else {
          this.reloadComponent();
          return
        }
      }
      if(target_data.reservation.state === 'busy'){
        if(this.checkdevice()) {  // check device id ipad, and browser is safari
          this.finalPosition = {x:event.clientX , y:event.clientY}
        }
        else{
          this.finalPosition = {x:event.clientX - this.initialPosition.x , y:event.clientY - this.initialPosition.y}
        }
        const elements = document.elementsFromPoint(this.finalPosition.x, this.finalPosition.y);
        const ele2 = elements.find(element => element.classList.contains("dropzone"));
        const req_data = this.getReservationIndex(this.current_table_id,this.current_reservation_id)
        this.target_reservation_time = ele2.dataset.index
        this.target_table_id = parseInt(ele2.dataset.id)
        this.target_table_name = ele2.dataset.info
        let set_hours = parseInt(this.target_reservation_time.split(':')[0])
        let set_minutes = parseInt(this.target_reservation_time.split(':')[1])
        const dateObject = moment(new Date(req_data.reservation.start).setHours(set_hours,set_minutes,0));
        req_data.reservation.start= dateObject.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        req_end_time = dateObject.clone()
        req_end_time = req_end_time.add(req_data.reservation.stay_minutes, "minute")
        req_data.reservation.shop_table_ids = [this.target_table_id];
      }
      else {
      if(req_data.reservation.is_table_merged){ // dragged table is merged reservation
        let tables = this.timeTable.map((t,i)=> t.reservations.some(reservation => reservation.id === req_data.reservation.id) ? {table_id:t.table.id,index:i}:{table_id:t.table.id,index:-1});
        this.getTable(tables,1,'selected') // getting all the tables list of selected reservation id
        this.current_tables.sort();
        if(target_data.reservation.is_table_merged){ // dropped table is merged reservation
          let tables = this.timeTable.map((t,i)=> t.reservations.some(reservation => reservation.id === target_data.reservation.id) ? {table_id:t.table.id,index:i}:{table_id:t.table.id,index:-1}); // table with save reservation is index otherwise indexed to -1
          this.getTable(tables,1,'target')
          this.target_tables.sort();
          let objectIndex = tables.findIndex(object => {
            return object.table_id === this.current_tables.sort()[0];
          });
          target_data_table = tables.slice(objectIndex,this.target_tables.length+objectIndex).map(t => t.table_id);
          let all_tables= [...new Set(target_data_table.concat(this.target_tables))]
          tables.forEach(function (item){
            if(!all_tables.includes(item.table_id) && (item.index !== -1)){
              target_data_table.push(item.table_id)
            }
          });
          target_data.reservation.shop_table_ids = target_data_table
          objectIndex = tables.findIndex(object => {
            return object.table_id === this.target_tables.sort()[0];
          });
          req_data_table = tables.slice(objectIndex,this.current_tables.length+objectIndex).map(t => t.table_id);
          all_tables= [...new Set(req_data_table.concat(this.current_tables))]
          tables.forEach(function (item){
            if(!all_tables.includes(item.table_id) && (item.index !== -1)){
              req_data_table.push(item.table_id)
            }
          });
          req_data.reservation.shop_table_ids = req_data_table
        }
        else{  // dropped table is not merged reservation
          let objectIndex = tables.findIndex(object => {
            return object.table_id === this.target_table_id;
          });
          req_data_table = tables.slice(objectIndex,this.current_tables.length+objectIndex).map(t => t.table_id);
          let all_tables= [...new Set(req_data_table.concat(this.current_tables))]
          tables.forEach(function (item){
            if(!all_tables.includes(item.table_id) && (item.index !== -1)){
              req_data_table.push(item.table_id)
            }
          });
          req_data.reservation.shop_table_ids = req_data_table
          target_data_table.push(this.current_tables.sort()[0])
          target_data.reservation.shop_table_ids = target_data_table
        }
      }
      else if (!req_data.reservation.is_table_merged){ // dragged table is not merged reservation
        if(target_data.reservation.is_table_merged){ // dropped table is not merged reservation
          let tables = this.timeTable.map((t,i)=> t.reservations.some(reservation => reservation.id === target_data.reservation.id) ? {table_id:t.table.id,index:i}:{table_id:t.table.id,index:-1});
          this.getTable(tables,1,'target')
          this.target_tables.sort();
          let objectIndex = tables.findIndex(object => {
            return object.table_id === this.current_table_id
          });
          target_data_table = tables.slice(objectIndex,this.target_tables.length+objectIndex).map(t => t.table_id);
          let all_tables= [...new Set(target_data_table.concat(this.target_tables))]
          tables.forEach(function (item){
            if(!all_tables.includes(item.table_id) && (item.index !== -1)){
              target_data_table.push(item.table_id)
            }
          });
          target_data.reservation.shop_table_ids = target_data_table
          req_data.reservation.shop_table_ids = this.target_tables.length > 0 ? [this.target_tables.sort()[0]] : [target_data_table[0]]
        }
        else{
          req_data.reservation.shop_table_ids = [this.target_table_id]
          target_data.reservation.shop_table_ids = [this.current_table_id]
        }
      }
      this.errors = ''
      const state = this.checkCommonTables(target_data.reservation,req_data.reservation)
      if(!state){
        this.errors ='予約日時の時間には既に予約があります。'
        return
      }}
      if(target_data.reservation.state === 'busy'){
        this.timeTable.map((t, i) => {
          if (t.table.id !== this.current_table_id && t.table.id === this.target_table_id) {
            t.reservations.push(req_data.reservation)
          }  else if (t.table.id === this.current_table_id && t.table.id !== this.target_table_id) {
            t.reservations.splice(req_data.index, 1)
          }
        });
        msg = `テーブル名： ${this.target_table_name}<br> 予約時間： ${moment(new Date(req_data.reservation.start)).format("HH:mm")} - ${req_end_time.format("HH:mm")}<br> へ変更します`;
        title = '予約の変更';
      }
      else{
        const current_table_name = req_data.table[0].table.name
        msg = `<div>${current_table_name}: ${moment(new Date(req_data.reservation.start)).format("HH:mm")}<br/>${this.target_table_name} : ${moment(new Date(target_data.reservation.start)).format("HH:mm")}<br/>上記の予約を交換します  </div>`;
        title = 'テーブル交換';
      }

         confirm = await this.$swal.fire({
          title: title,
          html: msg,
          customClass: {
            content: 'swal2-content',
            confirmButton: 'btn btn-primary btn-fill btn-unfocus',
            cancelButton: 'btn btn-outline-primary btn-fill'
        },
          icon:'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "変更する",
        cancelButtonText: "キャンセル",
        buttonsStyling: false,
        });
        if (confirm.isConfirmed) {
          try {
            let reservation_array = []
            const start_time = req_data.reservation.start
            const target_time = target_data.reservation.start
            if(target_data.reservation.state === 'busy'){
              reservation_array = [req_data.reservation]
            }
            else{
              reservation_array = [req_data.reservation, target_data.reservation].sort((a, b) => a.id - b.id)
              req_data.reservation.start = target_time
              target_data.reservation.start = start_time
            }
            for (let reservations in reservation_array) {
              let reservation =reservation_array[reservations]
              if(reservation.course_id === null){
                reservation["is_course"]  = 0
                reservation["course_ids"]  = []
              }
              else{
                reservation["is_course"] = reservation.course_id.length
                reservation["course_ids"]  = [reservation.course_id]
              }
              const start = moment(new Date(reservation.start));
              reservation["start_time"] = start.format("YYYY-MM-DD HH:mm");
              await this.updateWithDrag(reservation)
              if(this.errors !== ''){
                break
              }
            }
            if(this.errors === ''){
              this.reloadComponent();
            }
            else{
              this.reloadComponent();
            }
          } catch(error) {
            console.log(error)
            this.reloadComponent();
          }
        }
        else{
          this.reloadComponent();
        }

    },
    checkCommonTables(target_reservation,req_reservation){
     const common_tables = target_reservation.shop_table_ids.filter(value => req_reservation.shop_table_ids.includes(value));
     if (common_tables.length>0){
       const target_start_datetime= moment(new Date(req_reservation.start))
       const target_end_datetime= moment(target_start_datetime).add(target_reservation.stay_minutes, 'minute')
       const req_start_datetime= moment(new Date(target_reservation.start))
       const req_end_datetime= moment(req_start_datetime).add(req_reservation.stay_minutes, 'minute')
       if(target_start_datetime>req_start_datetime){
         return req_end_datetime<=target_start_datetime
       }
       else if(target_start_datetime<req_start_datetime){
         return target_end_datetime<=req_start_datetime
       }
       else{
         return true
       }
     }
     else{
       return true
     }
},
    handleDrop(event) {
      event.preventDefault()
      const elements = document.querySelectorAll('.custom-div');
      elements.forEach(function(element) {
        element.parentNode.removeChild(element);
      });
      this.removeEvents() // removing the added events
      let role = '' , toIndexElement= 0;
      role = this.button_role
      document.querySelectorAll('.memo-popup').forEach((el) => el.remove())
      this.finalPosition.x = event.clientX;
      this.finalPosition.y = event.clientY;

      if(this.isDragging) {
        this.isDragging = false;
        this.current_reservation_id = parseInt(this.button_index.split(',')[0]);
        this.current_table_id =parseInt(this.button_index.split(',')[1]);
      }
      else if(role === 'dragTime' && !this.isDragging){
        role = ''
        this.button_role = ''
      }
      toIndexElement = event.target.closest('[data-index]');
      if(toIndexElement !== null) {
        this.target_reservation_time = toIndexElement.getAttribute('data-index')
        this.target_table_id = parseInt(toIndexElement.getAttribute('data-id'))
        this.target_table_name = toIndexElement.getAttribute('data-info')
      }
      if(role==='dragTable'){
        this.checkReservationsToMove()
      }
      else if(role==='dragTime') {
        let divWorking = document.querySelectorAll(".resizablediv"+this.selectedReservation)[0]
        divWorking.style.setProperty('display', '');// setting resevation to display for clicking
        let boxes = divWorking.getBoundingClientRect();
        let w = boxes.width;
        const expanded_boxes = Math.floor(w/30) +1
        this.staying_minutes = expanded_boxes * 15
        const elements = document.elementsFromPoint(boxes.right , boxes.bottom);
        const ele2 = elements.find(element => element.classList.contains("dropzone"));
        //checking if the end of expanded reservation is in drop zone
        if(ele2 === undefined || ele2 === null){
          this.resetData(true)
          return
        }
        this.target_reservation_time = ele2.dataset.index
        this.target_table_id = parseInt(ele2.dataset.id)
        this.target_table_name = ele2.dataset.info
        this.checkReservationsTimeToMove()
      }
    },
    async checkReservationsTimeToMove(){
      let set_hours = parseInt(this.target_reservation_time.split(':')[0])
      let set_minutes = parseInt(this.target_reservation_time.split(':')[1])
      if(set_minutes === 45) {
        set_hours +=1
        set_minutes = 0
      }
      else{
        set_minutes +=15
      }
      const req_data = this.getReservationIndex(this.current_table_id,this.current_reservation_id)
      const dateObject = moment(new Date(req_data.reservation.start).setHours(set_hours,set_minutes,0));
      const new_formattedDate = dateObject.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      const target_date = moment(new Date(req_data.reservation.start)).add(this.staying_minutes, 'minute')
      req_data.reservation.end = new_formattedDate
      const msg_label = moment(new Date(req_data.reservation.start)).format("HH:mm") + ' - ' + target_date.format("HH:mm")
      const msg = `${msg_label}  へ時間を変更します`
      const confirm = await this.$swal.fire({
        title: `予約時間変更`,
        text: msg,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        icon:'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "変更する",
        cancelButtonText: "キャンセル",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        try {
          let table_datas = this.timeTable
              .filter(t => t.reservations.some(reservation => reservation.id === req_data.reservation.id)).map(t => t.table.id);
          req_data.reservation["shop_table_ids"] = table_datas
          if(req_data.reservation.course_id === null){
            req_data.reservation["is_course"]  = 0
            req_data.reservation["course_ids"]  = []
          }
          else{
            req_data.reservation["is_course"] = req_data.reservation.course_id.length
            req_data.reservation["course_ids"]  = [req_data.reservation.course_id]
          }
          req_data.reservation.stay_minutes = this.staying_minutes;
          await this.updateWithDrag(req_data.reservation);
          if(this.errors === '') {
            this.reloadComponent();
          }
        } catch(error) {
          console.log(error)
          this.resetData(true)
          this.reloadComponent();
        }
      }
      else{
        this.resetData(true)
      }
    },
    checkHourEndTime(hours, minutes){
      let end_minutes = this.formatHourTime(minutes,2)
      const data_ind = document.querySelector(`[data-index="${hours+':'+end_minutes}"]`);
      return !!data_ind;
    },
    async checkReservationsToMove(){
      let target_table = []
      const elements = document.elementsFromPoint(this.finalPosition.x, this.finalPosition.y);
      const ele2 = elements.find(element => element.classList.contains("dropzone"));
      const req_data = this.getReservationIndex(this.current_table_id,this.current_reservation_id)
      this.target_reservation_time = ele2.dataset.index
      this.target_table_id = parseInt(ele2.dataset.id)
      this.target_table_name = ele2.dataset.info
      let set_hours = parseInt(this.target_reservation_time.split(':')[0])
      let set_minutes = parseInt(this.target_reservation_time.split(':')[1])
      const dateObject = moment(new Date(this.date).setHours(set_hours,set_minutes,0));
      const end_time_obj = dateObject.clone()
      const end = end_time_obj.add(req_data.reservation.stay_minutes, 'minutes')
      if(!this.checkHourEndTime(set_hours,set_minutes)){
        this.reloadComponent();
        return
      }
      req_data.reservation.overlapLevel = 0

      if(!req_data.reservation.is_table_merged) {
        req_data.reservation.start = dateObject.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        this.timeTable.map((t, i) => {
          if (t.table.id !== this.current_table_id && t.table.id === this.target_table_id) {
            t.reservations.push(req_data.reservation)
          } else if (t.table.id === this.current_table_id && t.table.id === this.target_table_id) {
            t.reservations.splice(req_data.index, 1, req_data.reservation)
          } else if (t.table.id === this.current_table_id && t.table.id !== this.target_table_id) {
            t.reservations.splice(req_data.index, 1)
          }
        });
        this.timeTable.forEach(function(t){
          if(t.reservations.indexOf(req_data.reservation)>=0){
            target_table.push(t.table.id)
          }
        });
      }
      else{
      // dragged table is merged reservation

          let msg = `テーブルを複数選択している予約情報は<br>ドラッグ&ドロップでテーブル移動できません`;
          let title = 'テーブル移動できません';
          let confirm = await this.$swal.fire({
            title: title,
            html: msg,
            customClass: {
              content: 'swal2-content',
              confirmButton: 'btn btn-primary btn-fill btn-unfocus'
            },
            icon: 'warning',
            allowOutsideClick: false,
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          if (confirm.isConfirmed) {
            this.reloadComponent();
            return
          } else {
            this.reloadComponent();
            return

        }
      }
      const end_time  = end.format("HH:mm")
      const confirm = await this.$swal.fire({
        title: '予約の変更',
        html: `テーブル名： ${this.target_table_name}<br> 予約時間： ${dateObject.format("HH:mm")} - ${end_time}<br> へ変更します `,
        customClass: {
          content: 'swal2-content',
          confirmButton: 'btn btn-primary btn-fill btn-unfocus',
          cancelButton: 'btn btn-outline-primary btn-fill'
        },
        icon:'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "変更する",
        cancelButtonText: "キャンセル",
        buttonsStyling: false,
      });
      if (confirm.isConfirmed) {
        try {
          req_data.reservation["shop_table_ids"] = target_table
          req_data.reservation.start_time = dateObject.format("YYYY-MM-DD HH:mm");
          if(req_data.reservation.course_id === null){
            req_data.reservation["is_course"]  = 0
            req_data.reservation["course_ids"]  = []
          }
          else{
            req_data.reservation["is_course"] = req_data.reservation.course_id.length
            req_data.reservation["course_ids"]  = req_data.reservation.course_id
          }
          await this.updateWithDrag(req_data.reservation);
          if(this.errors === '') {
            this.reloadComponent()
          }
        } catch(error) {
          console.log(error)
          this.reloadComponent()
        }
      }
      else{
        this.reloadComponent()
      }
    },
    checkdevice() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          ||
          (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    getReservationIndex(table_id, reservation_id){
      let current_table = this.timeTable.filter(t => t.table.id === table_id);
      let current_reservation = current_table[0].reservations.filter(r => r.id ===reservation_id)[0];
      let current_index = current_table[0].reservations.indexOf(current_reservation);
      return {table:current_table,reservation:current_reservation,index:current_index}
    },
    getMinutes(date) {
      return moment(date).format('mm')
    },
    autoReload() {
      if (!this.loading) this.$emit('reload', true)
    },
    updateNowX() {
      this.nowX = this.getX(new Date())
      this.$nextTick(() => {
        this.$refs.dataWrapper.scrollTo(this.nowX - this.colW, 0)
      });
    },
    getTable(table, index,type) {
      if(type === 'selected') {
        if (index >= 0) {
          table.map((t, i) => {
            if (t.table_id === this.current_table_id && i > -1) {
              if (i > 0) {
                if (table[i - 1].index >= 0) {
                  if (!this.current_tables.includes(table[i].table_id)) {
                    this.current_tables.push(table[i].table_id)
                  }
                  if (!this.current_tables.includes(table[i - 1].table_id)) {
                    this.current_tables.push(table[i - 1].table_id)
                  }
                  this.current_table_id = table[i - 1].table_id
                  this.getTable(table, table[i - 1].index,'selected')
                } else {
                  if (!this.current_tables.includes(table[i].table_id)) {
                    this.current_tables.push(table[i].table_id)
                  }
                }
              }
            }
          });
        }
      }
      else{
        if (index >= 0) {
          table.map((t, i) => {
            if (t.table_id === this.target_table_id && i > -1) {
              if (i > 0) {
                if (table[i - 1].index >= 0) {
                  if (!this.target_tables.includes(table[i].table_id)) {
                    this.target_tables.push(table[i].table_id)
                  }
                  if (!this.target_tables.includes(table[i - 1].table_id)) {
                    this.target_tables.push(table[i - 1].table_id)
                  }
                  this.target_table_id = table[i - 1].table_id
                  this.getTable(table, table[i - 1].index,'target')
                } else {
                  if (!this.target_tables.includes(table[i].table_id)) {
                    this.target_tables.push(table[i].table_id)
                  }
                }
              }
            }
          });
        }
      }
    },
    getX(time) {
      const time_moment = moment(time)
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000

      return time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW
    },
    getWidth(start, stay_minutes) {
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return stay_minutes / 60 * this.colW -1
    },
    isBlockedTime(hour, minute, tableReservations) {
      const time = moment(this.date).set({hour, minute})
      return tableReservations.filter(r => r.state === 'busy').some(r => moment(r.start) <= time && time < moment(r.end))
    },
    cellClass(hour, minute) {
      const classes = ['cell', 'dropzone']
      if (minute === 45) classes.push('end-hour')
      const time = moment(this.date).set({hour, minute})
      if (!this.workingTimes.some(wt => moment(wt.start_time) <= time && time < moment(wt.end_time))) classes.push('outside-business-hour')
      return classes
    },
    getTimeX(start, stay_minutes) {
      const time_moment = moment(start)
      if (this.workingTimes.length < 1) return -1000
      if (time_moment.isBefore(this.timeTableStart)) return 0
      if (time_moment.isAfter(this.timeTableEnd)) return -1000
      const start_moment = moment(start)
      const end_moment = moment(start).add(stay_minutes, 'minutes')
      if (start_moment.isBefore(this.timeTableStart)) {
        stay_minutes = stay_minutes - moment(this.timeTableStart).diff(start_moment, 'minutes')
      }
      if (end_moment.isAfter(this.timeTableEnd)) {
        stay_minutes = stay_minutes - end_moment.diff(moment(this.timeTableEnd), 'minutes')
      }
      return time_moment.diff(moment(this.timeTableStart), 'minutes') / 60 * this.colW + (stay_minutes / 60 * this.colW -1)
    },
    newReservation(table, hour, minute, is_block = false) {
      const start_time = moment(this.date).set({hour, minute})
      const wt = this.workingTimes.find(wt => moment(wt.start_time) <= start_time && moment(wt.end_time) > start_time)
      this.editReservation = {
        shop_table_ids: [table.id],
        start_time: start_time.format('YYYY-MM-DD HH:mm'),
        last_name:'',
        number_of_people:  table.number_of_people_min,
        number_of_adults: table.number_of_people_min,
        reservation_type: 'phone',
        state:'reserved',
        reset_time: wt?.reset_time || 0,
        stay_minutes: wt?.stay_minutes || 120,
        is_block: is_block,
        tags_id: [],
      }
      this.showColorSelectModal=false;
      this.showReservationColor = false;
      this.showModalReservationBlock=false
      this.showReservationModal = true
    },
    walkReservation(table, hour, minute) {
      const start_time = moment(this.date).set({ hour, minute });
      const wt = this.workingTimes.find(wt => moment(wt.start_time) <= start_time && moment(wt.end_time) > start_time);

      this.editReservation = {
        shop_table_ids: [table.id],
        start_time: start_time.format('YYYY-MM-DD HH:mm'),
        last_name: 'ウォークイン',
        number_of_people:  table.number_of_people_min,
        number_of_adults: table.number_of_people_min,
        number_of_children: '',
        reservation_type:'walkin',
        state:'seating',
        reset_time: wt?.reset_time || 0,
        stay_minutes: wt?.stay_minutes || 120,
      };
      this.showColorSelectModal=false;
      this.showReservationColor = false;
      this.showModalReservationBlock=false
      this.showReservationModal = true
    },
    onClose() {
      this.showWalkReservationModal = false;
    },
    updateAdults(newCount) {
      this.editReservation.number_of_adults = String(newCount);
    },
    updateChildren(newCount) {
      this.editReservation.number_of_children = String(newCount);
    },
   checkClick(event,reservation) {
      this.counter++;
      if(this.counter == 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
          if(this.selectedReservation !== ''){
            return
          }
          this.openReservation(reservation,'reservation-modal');
        }, 300);
        return;
      }
      $('.reservation').removeClass('active')
      let boxes = document.querySelectorAll(".time-expand");
      boxes.forEach(box => {
        box.style.display = 'none';
      });

      const elements = document.elementsFromPoint(event.clientX, event.clientY);
      const ele2 = elements.find(element => element.classList.contains("reservation"));
      this.selectedReservation = ele2.dataset.index.split(',')[0]
      ele2.classList.add('active')
      clearTimeout(this.timer);
      boxes = document.querySelectorAll(".resizabletimediv"+this.selectedReservation);
      boxes.forEach(box => {
        box.style.display = 'block';
      });
      document.addEventListener('mousedown', this.startDrag, {passive:false});
      document.addEventListener('touchstart', this.startDrag, {passive:false});
      this.button_index = ele2.dataset.index;
      this.button_role = 'dragTime';
    },
    async openReservation(reservation, type='reservation-modal') {
      this.$emit('loading', true)
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.get(`${slug}/shop_manager/time_table/${reservation.id}`, {})
          .then((response) => {
            this.$emit('loading', false)
            this.showReservationTags = response.data.reservation.tag_details
            this.editReservation = response.data.reservation
            this.editReservation.overlap = reservation.overlap
            this.editReservation.mergeable_reservations = reservation.mergeable_reservations
            if (this.editReservation.customer_id != null) {
              this.getCustomer(this.editReservation.customer_id);
            }
            this.showColorSelectModal = false;
            this.showReservationColor = false;
            if (type === 'reservation-modal') {
              this.showNewReservation = true;
              this.showReservationModal = false;
              this.showPeoplesEditModal = false;
            } else if (type === 'peoples-edit-modal') {
              this.showNewReservation = true;
              this.showReservationModal = false;
              this.showPeoplesEditModal = true;
            } else {
              this.showReservationModal = true;
              this.showNewReservation = false;
              this.showPeoplesEditModal = false;
            }
            this.showModalReservationBlock = false;
          })
          .catch((error) => {
            console.log(error);
            this.$emit('loading', false)
          });
    },

    async openMergeModal(reservation) {
      this.mergeReservation = reservation
      this.showMergeModal = true
    },
    ChangeReservation(reservation){
      this.editReservation = reservation
    },
    updateLoading(val) {
      this.$emit('loading', val)
    },
    handleScroll() {
      const top = this.$refs.dataWrapper.getBoundingClientRect().top * -1
      this.headerTop = top < 0 ? 0 : top
    },
    async getCustomer(customer_id) {
      this.$emit('loading', true);
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/customer/${customer_id}`;

      await Http.get(path, {

      })
          .then((response) => {
            this.customerEdit = response.data.customer;
            this.customerReservations = response.data.reservations;
            this.$emit('loading', false)
          })
          .catch((error) => {
            this.$emit('loading', false)
            console.log(error);
          });
    },
    hasNotice(reservationId) {
      return this.notices.some(notice => notice.reservation_id === reservationId);
    },
    ShowChooseReservationModal(){
      this.showChooseModal = true
    },
    async openReservationBlockModal(table) {
      await this.getReservations(table.id)

      this.workingTime = {
        start_time: this.workStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.workEnd.format('YYYY-MM-DD HH:mm'),
      }

      this.editReservation = {
        shop_table_ids: table.id ? [table.id] : [],
        start_time: this.workStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.workEnd.format('YYYY-MM-DD HH:mm'),
        start_date: this.workStart
      }
      this.showModalReservationBlock = true
      this.showReservationModal=false
      this.showColorSelectModal=false;
      this.showReservationColor=false;
    },
    async getReservations(table_id) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/reservation_block/search_block_by_table`;

      await Http.get(path, {
        reserves_date_start: this.workStart.format('YYYY-MM-DD HH:mm:ss'),
        reserves_date_end: this.workEnd.format('YYYY-MM-DD HH:mm:ss'),
        shop_table_id: table_id
      }).then((response) => {
        this.reservationBlocks = response.data.reservations;
        this.datetime_blocks = []
        this.reservationBlocks.forEach((element) => {
          this.datetime_blocks.push({
            id: element.id,
            start_time: moment(element.start_time).format('HH:mm'),
            end_time: moment(element.end_time).format('HH:mm'),
            _destroy: false
          })
        });
      })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    async updateWithDrag(reservation){
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/${reservation.id}`
      await Http.post(path, { reservation: reservation,
        update_user_data: false,
        customer_id: null,
        send_mail: false,
        is_drag:true
      })
          .then((response) => {
            this.errors = ''
          })
          .catch((error) => {
            this.errors = error.response.data.start_time[0]
          });
    },

    showPopups(event, el, rId) {
      const reservation = this.timeTable.map(t => t.reservations).flat().find(r => r.id === rId)
      document.querySelectorAll('.memo-popup').forEach((el) => el.remove())
      const popup = document.createElement('div');
      popup.classList.add('memo-popup');
      document.body.appendChild(popup);
      popup.style.visibility = 'hidden';
      const titles = ['お店のメモ','予約に関する備考']
      const content = [reservation.memo, reservation.memo_from_customer]
      content.map((el, i) => {
        if (el !== null && el !== '') {
          const paragraph = document.createElement('p')
          const heading = document.createElement('span')
          const bodytext = document.createElement('span')
          heading.innerText = titles[i]
          heading.style.fontWeight = '600'
          bodytext.innerText = el
          paragraph.appendChild(heading)
          paragraph.appendChild(document.createElement('br'))
          paragraph.appendChild(bodytext)
          popup.appendChild(paragraph)
        }
      })
      const ele = event.srcElement.closest('.reservation')
      const popupWidth = popup.getBoundingClientRect().width;
      const popupHeight = popup.getBoundingClientRect().height;
      popup.style.width = `${popupWidth}px`;
      popup.style.left = `${ele.getBoundingClientRect().left + ele.getBoundingClientRect().width / 2 - popupWidth / 2 + document.body.scrollLeft}px`;
      popup.style.top = `${ele.getBoundingClientRect().top - popupHeight + 2 + document.body.scrollTop}px`;
      popup.style.visibility = '';
      el.$popupElement = popup;
    },
     formatHourTime(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    hidePopup(event, el) {
      if (el.$popupElement) {
        if (document.body.contains(el.$popupElement)) {
          document.body.removeChild(el.$popupElement);

          delete el.$popupElement;
        }
      }
    },
    handlePeopleUpdateSuccess(){
      this.showPeoplesEditModal = false;
      this.showNewReservation = false;
      this.showNewReservationModal = false;
    }
  },
  directives: {
    'popup': {
      inserted: function (el, binding, vnode) {
        if (binding.value === null || binding.value === undefined) return;

        let longPressTimer = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.timetable {
  display: flex;
  background-color: #FFFFFF;
  min-width: 100%;
  min-height: 345px;
  position: relative;
}

.label-wrapper {
  border-right: 1px solid #66615b15;
  box-shadow: 2px 0px 8px 0px #00000010 !important;

  .shop-table-name {
    width: 150px;
    border-right: 1px solid #66615b15;
    color: var(--color-table-name);
    margin-left: 20px;
  }

  #shop-table-name {
    .shop-table-name {
      margin-left: 20px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }
  }
  .shop-table-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-main);
  }
  .capacity {
    width: 50px;
  }
  .table-row {
    display: flex;
    align-items: center;
    border-top: 1px solid #66615b59;
  }
  .scrollbar-space {
    height: 14px;
    border-top: 1px solid #66615b15;
  }
}

.timetable-main-container{
  position: relative;
}

.data-wrapper {
  background-color:white;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  position: relative;

  .hour {
    width: var(--colW);
    text-align: left;
    padding-left: 4px;
    border-right: 1px solid #66615b15;
  }
  .scroll-wrapper {
    position: relative;
    display: table;
    border-bottom: 1px solid #66615b15;
  }
  .table-row {
    display: flex;
    border-top: 1px solid #CCCCCC;
    .cell {
      border-right: 1px dashed #DDDDDD;
      width: var(--cellW);
      cursor: pointer;
      &:hover {
        background-color: #66615b15;
      }
      &.end-hour {
        border-right: 1px solid #CCCCCC;
      }
      &.outside-business-hour {
        background-color: var(--color-businesshour-background);
      }
      &.busy {
        background-color: var(--color-tt-block-bg);
        opacity: 0.5;
      }
    }
  }
  .current-time-line {
    height: calc(100% + var(--headerH)*(-1px));
    width: 4px;
    background-color: var(--color-text-reservation);
    display: block;
    position: absolute;
    top: var(--headerH);
    opacity: 0.4;
  }
  .square-number-test{
    background-color: var(--color-text-reservation);
    min-width: 15px;
    height: 15px;
    color: var(--color-white);
    //display: inline-block;
    text-align: center;
    //font-size: 12px;
    line-height: 14px;
    border-radius: 3px;
    margin-right: 15px;
    margin-left: 3px;
  }
  .reservation {
    position: absolute;
    background-color: #FFFFFF;
    min-width: var(--cellW);
    font-size: 12px;
    cursor: pointer;
    border-right: 1px solid #66615b15;
    user-select: none;
    -webkit-user-select: none;
    //z-index: 1;
    .square-number{
      background-color: var(--color-main);
      min-width: 14px;
      height: 14px;
      color: var(--color-white);
      display: inline-block;
      text-align: center;
      font-size: 11px;
      line-height: 14px;
      border-radius: 3px;
    }
    .table-merge-icon{
      position:absolute;
      left:5px;
      bottom:2px;
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      img {
        width: 14px;
      }

    }
    .name{
      position: absolute;
      top: 20px;
      //left: 0px;
    }
    .full-name {
      font-weight: normal;
      margin-left: 1px;
      font-size: 12px;
      line-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      &.merge-table {
        //left: 20px;
        //padding-right: 45px;
        top: 20px;
      }
    }
    .number-of-people {
      position:absolute;
      top:2px;
      left: 2px;

    }
    .type {
      position:absolute;
      left:25px;
      top:3px;
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 40%;
    }
    .tag {
      position: absolute;
      top: 2px;
      right: 2px;
      white-space: nowrap;
      font-size: 1rem;
      display: flex;
      justify-content: flex-end;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .course-name {
      font-weight: normal;
      position:absolute;
      left: 2px;
      top: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      white-space: nowrap;
      display: block;
      font-size: 12px;
    }
    &.reserved {
      background-color:var( --color-white);
      color: var(--color-metal-grey);
      border-radius: 5px 5px 5px 5px;
    }
    &.seating {
      background-color:var(--color-seating-background);
      color: var(--color-medium-grey);
      border-radius: 5px 5px 5px 5px;
      .square-number {
        color: var(--color-main) !important;
        background: #fff !important;
      }
    }
    &.finished {
      background-color: var(--color-off-white);
      color: var(--color-medium-grey);
      border-radius: 5px 5px 5px 5px;
    }
    &.busy {
      background-color: var(--color-tt-block-bg);
      opacity: 0.5 !important;
      z-index: 0;
      border-radius: 5px 5px 5px 5px;
    }
    &.not_show {
      background-color: var(--color-no-show);
      color: var(--color-white);
      border-radius: 5px 5px 5px 5px;
      display: none;
    }
    @keyframes border-blink { 50% { border-color:transparent ; }  }
    &.overlap {
      animation: border-blink 1.5s;
      animation-iteration-count: infinite;
    }
  }
}
.drag-error{
  color: var(--color-error);
}
.time-expand{
  cursor: ew-resize;
  position: absolute;
  display: none;
  z-index:1000;
}
.label-wrapper, .data-wrapper {
  .table-header {
    display: flex;
    background-color:var(--color-text-reservation);
    color: var(--color-white);
    font-weight: 600;
    position: absolute;
    z-index: 10;
  }
  .table-header-first{
    display: flex;
    background-color:#F1EFED;
    color: var(--color-main);
    font-weight: 600;
    position: absolute;
    z-index: 10;
  }
}

.loading {
  z-index: 3000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffa1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-while {
  color: var(--color-main);
}
.reservation {
  font-weight: bold;
  &:hover {
    opacity: 0.7;
  }
}
.opacity-unset {
  opacity: unset !important;
}

.my-fork{
  background: var(--color-tt-course);
  padding: 1px;
  border-radius: 50%;
}
.my-bell{
  background: var(--color-tt-today);
  padding: 1px;
  border-radius: 50%;
}
.my-message{
  background: var(--color-tt-message);
  padding: 1px;
  border-radius: 50%;
}
.my-primary {
  background: var(--color-main);
  padding: 1px;
  border-radius: 50%;
}

.right-sidebar{
  background-color:var(--color-white);
  position:absolute;
  height:100vh;
  top:0;
  right:0;
  z-index:2000;
  width:0%;
  transition:width 0.2s linear;
  display:flex;
}
.active{
  border: 5px solid var(--color-error) !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.expandedRightSidebar {
  background-color:var(--color-white);
  width:580px;
  position:fixed;
}

/* css for ipad */
@media only screen and (min-width:480px) and (max-width:1000px){
  .expandedRightSidebar{
    width:580px;
  }
}

/* css for mobile */
@media only screen and (max-width:480px){
  .expandedRightSidebar{
    width:100%;
  }
  .swal-modal .swal-text {
    text-align: center;
  }
}

.reservation-main-container{
  display:flex;
}
.color-label{
  width:20px;
  background: var(--color-tt-message);
  border-radius: 5px 0px 0px 5px;

}
.reservation-container{
  width:100%;
  display:flex;
  //border: 2px dashed var(--color-tt-message);
  border-radius: 5px 5px 5px 5px;
}
.reservation-content{
  width:100%;
  position:relative;
  overflow:hidden;
  border-radius: 0px 5px 5px 0px;
  &.reserved {
    border: 4px solid var(--color-reserved-border);
  }
  &.seating {
    border: 4px solid var(--color-seating-border);
  }
  &.finished {
    border: 2px solid var(--color-medium-grey);
  }
}
.property-icon{
  color: var(--color-error);
  position: relative;
  top: 2px;
  padding-right: 5px;
  font-size: 14px;
}
.tag-svg {
  //position: relative;
  top: 5px;
  width: 21px;
  height: 16px;
  color: var(--color-text-reservation);
  background: var(--color-white);
  margin-right: 3px;
  border-radius: 2px;

  path {
    fill: currentColor;
  }
}
.tag-icon{
  text-align: center;
  background: var(--color-white);
  color: var(--color-text-reservation);
  font-size:0.8rem;
  padding-left:0.15rem;
  padding-top:0.1rem;
  padding-bottom:0.1rem;
  padding-right:0.2rem;
  margin-right:3px;
  width: 21px;
}
.table-name{
  font-size: 14px;
  width:8em;
  font-weight:600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table-no{
  font-size: 12px;
}
.time-table-footer{
  position: fixed;
  background: transparent;
  right: 1.5rem;
  bottom: 3rem;
  height: 12rem; /* Adjust as needed */
  color: var(--color-text-reservation);
  text-align: right;
  z-index: 998;
  display:flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  //line-height: 10rem; /* Center text vertically */
}
.color-main{
  color: var(--color-main);
}
.tag-icon-fa {
  //position: relative;
  border-radius: 2px;
}

.new-reservation-modal {
  z-index: 10;
}

.peoples-edit-modal {
  z-index: 20;
}
</style>
