import { render, staticRenderFns } from "./CustomerView.vue?vue&type=template&id=1c43d0aa&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./CustomerView.vue?vue&type=script&lang=js&"
export * from "./CustomerView.vue?vue&type=script&lang=js&"
import style0 from "./CustomerView.vue?vue&type=style&index=0&id=1c43d0aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c43d0aa",
  null
  
)

export default component.exports