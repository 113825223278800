<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">確認</h5>
          <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <span>本当に削除しますか?</span>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="btn btn-outline-primary btn-unset btn-round">キャンセル</button>
          <button @click="deleteAnniversary" class="btn btn-round delete-button">削除</button>
        </div>
      </div>
    </div>
    <!--    <div class="modal-backdrop show"></div>-->
  </div>

</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('close-delete-modal', true)
    },
    deleteAnniversary() {
      this.$emit('deleteAnniversary', true)
    }
  },
}
</script>

<style scoped>
.modal-dialog {
  z-index: 2000;
}

.cross-button {
  color: var(--color-tt-finished);
  font-weight: bold;
  font-size: 24px;
}

.modal-title {
  font-weight: bold;
}

.modal-footer {
  justify-content: center;
}

.btn, span {
  font-size: 16px;
}

.delete-button {
  background-color: var(--color-dusty-rose);
  color: var(--color-white);
  border: 1px solid var(--color-dusty-rose);
}

</style>