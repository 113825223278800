<template>
  <div >
    <div v-if="showMemoListModal" class="modal fade show rounded-lg" style="display: block;">
      <div class="modal-dialog modal-dialog-scrollable new-memo-modal-size" style="float: right">
        <div class="new-memo-modal-content modal-content">
          <div class="modal-header new-memo-modal-header">
            <div class="new-memo-modal-title">引き継ぎメモ</div>
            <button type="button" class="close close_reservation_modal close-block text-white" @click="onClose"><span>&times;</span></button>
          </div>

          <div class="modal-body pd-0 modal-body-size">
            <div v-for="(memo, index) in memoList" :key="index" class="memo-item-container">
              <div class="memo-item-heading">
                <p class="memo-number-title">引き継ぎメモ{{ index + 1 }}</p>
                <p class="memo-delete-action" @click="deleteMemo(memo.id)">削除</p>
              </div>
              <div class="memo-text-container">
                {{ memo.content }}
              </div>
            </div>
            <template v-if="memoList.length === 0">
              <div>メモがありません</div>
            </template>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn  new-memo-create-button" @click="onCreateNew">新規作成</button>
          </div>
        </div>
      </div>
    </div>
    <div class="memo-modal-background" v-if="showModalMemo" @click="showModalMemo = false; showMemoListModal = true"></div>
    <MemoModal v-if="showModalMemo" @close="showModalMemo = false; showMemoListModal = true" :date="date" @save="saveMemo"/>
  </div>
</template>

<script>
import moment from 'moment';
import MemoModal from "./MemoModal.vue";
import Http from "../../../shared/http";

export default {
  components: { MemoModal},
  props: {
    date: {
      type: Date,
    },
    memoList: {
      type: Array,
    },
  },

  data() {
    return {
      content: '',
      showMemoListModal: true, // Initially show memoListModal
      showModalMemo: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    async onCreateNew(){
      this.showModalMemo = true
      this.showMemoListModal = true // Close memoListModal when MemoModal opens
    },
    saveMemo(params) {
      this.$emit('save-memo', params);
      this.showModalMemo = false;
      this.showMemoListModal = true;
    },
    async deleteMemo(memoId) {
      try {
        await Http.delete(`/shop_manager/shop_memos/${memoId}`);
        this.$emit('reload-memo', new Date())
      } catch (error) {
        console.error('Error deleting memo:', error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.memo-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}
.modal-dialog-scrollable {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  height: 100dvh !important; /* Full height of the viewport */
  margin: 0 !important;
}

.modal-content {
  height: 100% !important; /* Ensure modal content takes full height */
}

.modal-body {
  overflow-y: auto !important; /* Allow scrolling within the modal body if content exceeds height */
  max-height: 85dvh; /* Adjust based on the height of modal-header and modal-footer */
}

.modal-dialog-scrollable .modal-content {
  max-height: 100dvh !important;
}

.modal-dialog {
  min-height: 100dvh;
  margin: 0px !important;
  justify-content: unset !important;
}
@media (max-width: 767px) {
  .modal-dialog {
    min-width: 70vw !important; /* Ensure minimum width is also 100% of viewport width for mobile */
  }
}
.shop-memo {
  cursor: text;
  line-height: 1.6;
  font-size: 14px;
  max-height: 600px;
  &:disabled{
    background: #fff;
    border: none;
  }
}

.new-memo-modal-size{
  min-width: 512px;
}

.new-memo-modal-header{
  background: var(--color-text-reservation);
  height: 46px;
  display: flex;
  align-items: center;
}

.memo-text-container{
  border-radius: 4px;
  padding: 12px;
  background: var(--main_opacity5);
  margin-bottom: 20px;
}

.memo-item-heading{
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.memo-number-title{
  color: var(--color-table-name);
  font-size: 12px;
  font-weight: normal;
}
.memo-delete-action{
  font-size: 12px;
  font-weight: normal;
  color: red;
  cursor: pointer;
}

.new-memo-modal-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
}
.modal-body-size{
  min-height: 475px;
}

.new-memo-create-button{
  width: 180px;
  background: var(--color-text-reservation);
  color: white;
}

.memo-list-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.memo-number{
  background: yellow;
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.memo-modal-sub-header{
  color: var(--color-main);
  font-size: 12px;
  font-weight: normal;
}
</style>