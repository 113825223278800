<template>
  <div class="reservation-footer-nav">
    <RecordingIndicator :active="isRecordingActive" v-if="recording"/>
    <div v-else>
    </div>
    <div class="nav-btn">
      <button v-if="step>1" class="btn btn-lg back-button" @click="goBack">もどる</button>
      <button class="btn btn-lg" :key="isLastPage" :class="isLastPage ? 'next-btn' : 'btn-primary'" @click="nextStep" style="margin:20px; font-size: 16px">{{ isLastPage ? '登録する' : '次へ'}}</button>
    </div>
    <SelectReservationMethod v-show="activeReservation.showReservationMethod" :staffs="reservation_staffs" @submit-reservation="ConfirmReservation"></SelectReservationMethod>
    <ValidationModal :title="'入力エラー'" @close="closeValidationModal" v-if="activeReservation.showValidationMessage"/>
  </div>
</template>
<script>
import RecordingIndicator from "../../components/RecordingIndicator.vue";
import stores from "../../stores";
import {validationMessages} from "../../validator/reservationValidationMessages";
import ValidationModal from "../../components/ValidationModal.vue";
import Http from "../../../shared/http";
import SelectReservationMethod from "../../components/SelectReservationMethod.vue";
import {decode_data} from "../../../shared/util";
import moment from 'moment';

export default {
  components: {SelectReservationMethod, ValidationModal, RecordingIndicator},
  props:{
    isRecordingActive:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      recording:false,
      isLastPage:false,
      validationErrorMessages:[],
      shop_tables: decode_data("#data-shop-tables"),
      reservation_staffs: decode_data("#data-reservation-staffs"),
    }
  },
  computed:{
    activeReservation() {
      return stores.getters.getActiveReservation;
    },
    step(){
      return stores.getters.getActiveReservation.current_step;
    }
  },
  watch:{
    step(newVal){
      const route_path= this.$route.path;
      const step = route_path.split('/')[route_path.split('/').length-1];
      if (step !== this.activeReservation.completed_step+1 && step > this.activeReservation.completed_step+1) {
        this.$router.push(this.get_new_route(this.activeReservation.completed_step + 1));
        stores.commit('SET_STEP',parseInt(step))

      }
      else {
        this.$router.push(this.get_new_route(newVal));
      }
      this.isLastPage = this.checkIfLastPage();
    }
  },
  mounted() {
    const route_path = this.$route.path.split('/');
    stores.commit('SET_STEP',parseInt(route_path[route_path.length-1]))
  },
  methods:{
    extractMessages(data) {
      const messages = [];
      for (const key in data) {
        if (data[key].length > 0) {
          messages.push(data[key][0]);
        }
      }
      return messages;
    },
    goBack(){
      stores.commit('SET_STEP',(this.step!==1 ? this.step-1:this.step))
    },
    nextStep(){
      this.validateData(this.step)
      if(this.validationErrorMessages.length>0){
        return
      }
      if(this.isLastPage && !this.isWalkIn()){
        stores.commit('SET_SHOW_RESERVATION_MODAL',true)
      }
      else if(this.isLastPage && this.isWalkIn()){
        this.ConfirmWalkinReservation();
        return
      }
      stores.commit('SET_COMPlETED_STEP', this.step)
      stores.commit('SET_STEP',(this.step!==6 ? this.step+1: this.step))
    },
    async ConfirmWalkinReservation() {
      const data = this.activeReservation
      const number_of_people = Number(data.data.number_of_adults !== '' ? data.data.number_of_adults : 0)+ Number(data.data.number_of_children !== '' ? data.data.number_of_children : 0)
      let _data = {
        reservation: {
          is_course: 0,
          shop_table_ids: data.data.selected_seats,
          start_time: `${data.data.date} ${data.data.hours}:${data.data.minutes}`,
          stay_minutes: data.data.stay_minutes,
          number_of_people: number_of_people,
          number_of_adults: data.data.number_of_adults !== '' ? data.data.number_of_adults : 0,
          number_of_children: data.data.number_of_children !== '' ? data.data.number_of_children : 0,
          reservation_type: "walkin",
          first_name: "",
          last_name: "ウォークイン",
          state: "seating",
          uuid:data.uuid,
          course_ids: []
        },
        update_user_data: false,
        customer_id: null,
        send_mail: false
      }
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/`
      await Http.post(path, _data)
          .then((response) => {
            this.$emit('confirm-reservation')
          })
          .catch((error) => {
            if(error.response.data !== null){
              stores.commit('SET_ERROR_MESSAGE', error.response.data)
              stores.commit('SET_SHOW_VALIDATION',true)

            }
          });

    },
    closeValidationModal(){
      stores.commit('SET_SHOW_VALIDATION',false)
    },
    get_new_route(step){
      const route_path = this.$route.fullPath.split('/');
      const query=route_path.pop().split('?')[1]
      route_path.push(`${step}?${query}`);
      return route_path.join('/');
    },
    isWalkIn(){
      return this.activeReservation.reservation_type === 'walk-in';
    },
    checkIfLastPage(){
      return (this.isWalkIn() && this.step === 2) || (!this.isWalkIn() && this.step === 6);
    },
    isInExistingReservationTime(newReservationStart,newReservationEnd,reservationStart,reservationEnd){
      return (
          newReservationStart.isSame(reservationStart) ||  newReservationEnd.isSame(reservationEnd) ||
          (newReservationStart.isBefore(reservationEnd) && newReservationStart.isAfter(reservationStart)) ||
          (newReservationEnd.isAfter(reservationStart) && newReservationEnd.isBefore(reservationEnd))
      );
    },
    possibleReservationTime(startTime){
      let dateToChange=new Date(startTime)
      const stayMinutes = this.activeReservation.data.stay_minutes
      return {
        startTime: startTime,
        endTime: new Date(dateToChange.setMinutes(dateToChange.getMinutes() + stayMinutes))
      }
    },
    getDateTime(date,time){
      let dateTimeString = `${date}T${time}`;
      return new Date(dateTimeString);

    },
    hasMatchingTableId(existingTables, selectedSeats) {
      return existingTables.some(seat => selectedSeats.includes(seat.table.id));
    },
    filterTablesWithNoExistingReservation(startTime,tables) {
      let newReservationDateTime = this.possibleReservationTime(startTime)
      return tables.filter(element => {
        return element.reservations.some(reservation => {
          const reservationTimeStart = moment(reservation.start.replace(/(\.\d+)?([+-]\d{2}:\d{2})$/, ''))
          const reservationTimeEnd = moment(reservation.end.replace(/(\.\d+)?([+-]\d{2}:\d{2})$/, ''))
          const newReservationTimeStart = moment(newReservationDateTime.startTime)
          const newReservationTimeEnd = moment(newReservationDateTime.endTime)
          return this.isInExistingReservationTime(newReservationTimeStart,newReservationTimeEnd,reservationTimeStart,reservationTimeEnd)
        });
      });
    },
    hasMorePeople(){
      const selectedSeats = this.activeReservation.data.selected_seats
      const tables = this.activeReservation.data.timeTable.map(t => t.table)
      const selected_number_of_people = (this.activeReservation.data.number_of_adults!=='' ? Number(this.activeReservation.data.number_of_adults) : 0) + (this.activeReservation.data.number_of_children!=='' ? Number(this.activeReservation.data.number_of_children) : 0)
      let max_people_in_table = 0
      tables.some((t) => {
        if(selectedSeats.includes(t.id)){
          max_people_in_table = t.number_of_people_max + max_people_in_table
        }
      });
      if (selected_number_of_people > max_people_in_table) {
        return true
      }
      else{
        return false
      }
    },
    hasExistingReservation(){
      let startTime = `${this.activeReservation.data.hours}:${this.activeReservation.data.minutes}`
      let newReservationDate = this.activeReservation.data.date
      let startDateTime = this.getDateTime(newReservationDate,startTime)
      let timeTable = this.activeReservation.data.timeTable
      const selectedSeats = this.activeReservation.data.selected_seats
      const seatsWithExistingReservation = this.filterTablesWithNoExistingReservation(startDateTime,timeTable)
      return this.hasMatchingTableId(seatsWithExistingReservation, selectedSeats)
    },
    validateData(step){
      let errorMessages =[]
      this.validationErrorMessages = []
      stores.commit('SET_ERROR_MESSAGE',{})

      if(step===1){
        let time = this.getTimeOrNull()
        if(this.activeReservation.data.number_of_children==='') stores.commit('SET_DATA',{number_of_children:'0'})
        const total_number_of_people = (this.activeReservation.data.number_of_adults!=='' ? Number(this.activeReservation.data.number_of_adults) : 0) + (this.activeReservation.data.number_of_children!=='' ? Number(this.activeReservation.data.number_of_children) : 0)
        if(time === null) errorMessages.push(validationMessages.BLANK_RESERVATION_TIME)
        if(this.activeReservation.data.date === null) errorMessages.push(validationMessages.BLANK_RESERVATION_DATE)
        if (total_number_of_people === 0) {
          errorMessages.push(validationMessages.SELECT_TOTAL_NUMBER_OF_PEOPLE);
        } else if (this.activeReservation.data.number_of_adults === null ||
            parseInt(this.activeReservation.data.number_of_adults) < 1 ||
            this.activeReservation.data.number_of_adults === '') {
          errorMessages.push(validationMessages.NO_ADULTS_SELECTED);
        }
        if(parseInt(total_number_of_people)>48) errorMessages.push(validationMessages.MAX_NUMBER_OF_PEOPLE)
        this.validationErrorMessages = errorMessages
      }
      else if(step===2){
        if(this.activeReservation.data.selected_seats.length<1){
          errorMessages.push(validationMessages.NO_SELECTED_SEATS)
          this.validationErrorMessages = errorMessages
        }
        else if(this.hasMorePeople()){
          errorMessages.push(validationMessages.MAX_PEOPLE_EXCEEDED)
          this.validationErrorMessages = errorMessages
        }
        if( this.hasExistingReservation()===true){
          errorMessages.push(validationMessages.SELECTED_TABLE_WITH_EXISTING_RESERVATION)
          this.validationErrorMessages = errorMessages
        }
      }
      else if(step===3){
        const phoneNumberPattern = /^\+?\d{1,15}$/;
        const { first_name, last_name, tel, company_name, first_name_kana, last_name_kana } = this.activeReservation.data;
        const errorMessages = [];
        const isFirstNameEmpty = !first_name || first_name.trim() === "";
        const isFirstNameTooLong = first_name && first_name.length > 255;
        const isLastNameEmpty = !last_name || last_name.trim() === "";
        const isLastNameTooLong = last_name && last_name.length > 255;
        if (isFirstNameEmpty && isLastNameEmpty) {
          errorMessages.push(validationMessages.BLANK_NAME);
        } else {
          if (isFirstNameTooLong) {
            errorMessages.push(validationMessages.TOO_LONG_FIRST_NAME);
          }
          if (isLastNameTooLong) {
            errorMessages.push(validationMessages.TOO_LONG_LAST_NAME);
          }
        }
        const katakanaRegex = /^[ァ-ンヴー]*$/;
        const isFirstNameKanaInvalid = first_name_kana && !katakanaRegex.test(first_name_kana);
        const isFirstNameKanaTooLong = first_name_kana && first_name_kana.length > 255;
        const isLastNameKanaInvalid = last_name_kana && !katakanaRegex.test(last_name_kana);
        const isLastNameKanaTooLong = last_name_kana && last_name_kana.length > 255;
        if (isFirstNameKanaInvalid && isLastNameKanaInvalid) {
          errorMessages.push(validationMessages.INVALID_NAME_KANA);
        } else {
          if (isFirstNameKanaInvalid) {
            errorMessages.push(validationMessages.INVALID_FIRST_NAME_KANA);
          }
          if (isFirstNameKanaTooLong) {
            errorMessages.push(validationMessages.TOO_LONG_FIRST_NAME);
          }
          if (isLastNameKanaInvalid) {
            errorMessages.push(validationMessages.INVALID_LAST_NAME_KANA);
          }
          if (isLastNameKanaTooLong) {
            errorMessages.push(validationMessages.TOO_LONG_LAST_NAME);
          }
        }
        const isCompanyTooLong = company_name && company_name.length > 255;
        if (isCompanyTooLong) {
          errorMessages.push(validationMessages.TOO_LONG_COMPANY_NAME);
        }
        const isTelEmpty = !tel || tel.trim() === "";
        if (isTelEmpty || !phoneNumberPattern.test(tel)) {
          errorMessages.push(validationMessages.BLANK_PHONE);
        }
        this.validationErrorMessages = errorMessages;
      }
      if(this.validationErrorMessages.length>0){
        stores.commit('SET_ERROR_MESSAGE',this.validationErrorMessages)
        stores.commit('SET_SHOW_VALIDATION',true)
      }
    },
    getTimeOrNull(){
      if(this.activeReservation.data.hours ==="" || this.activeReservation.data.minutes ===""){
        return null
      }
      return this.activeReservation.data.hours+':'+this.activeReservation.data.hours
    },
    async ConfirmReservation(){
      stores.commit('SET_SHOW_RESERVATION_MODAL',false);
      stores.commit('SET_ERROR_MESSAGE',{});
      const data = this.activeReservation;

      const number_of_people = Number(data.data.number_of_adults !== '' ? data.data.number_of_adults : 0)+ Number(data.data.number_of_children !== '' ? data.data.number_of_children : 0)
      let _data = {
        update_user_data: false,
        send_mail: false,
        customer_id :data.data.customer_id,
        reservation:{
          is_course:data.data.is_course,
          shop_table_ids: data.data.selected_seats,
          stay_minutes: data.data.stay_minutes,
          number_of_people: number_of_people,
          number_of_adults: data.data.number_of_adults !== '' ? data.data.number_of_adults : 0,
          number_of_children: data.data.number_of_children !== '' ? data.data.number_of_children : 0,
          reservation_type: data.data.reservation_type,
          first_name: data.data.first_name,
          last_name: data.data.last_name,
          first_name_kana: data.data.first_name_kana,
          last_name_kana: data.data.last_name_kana,
          state: "reserved",
          tel:data.data.tel,
          course_ids: data.data.course_ids === "" ? [] : [Number(data.data.course_ids)] ,
          memo: data.data.note,
          start_time: `${data.data.date} ${data.data.hours}:${data.data.minutes}`,
          staff_name: data.data.staff,
          uuid:data.uuid,
          tags_id: data.data.tags
        }
      }
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/`
      await Http.post(path, _data)
          .then((response) => {
            this.$emit('confirm-reservation')
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              this.validationErrorMessages = this.extractMessages(error.response.data)
              stores.commit('SET_ERROR_MESSAGE', this.validationErrorMessages)
              stores.commit('SET_SHOW_VALIDATION',true)
            }
          });
    },

  }
}
</script>
<style scoped>
.reservation-footer-nav{
  display: flex;
  height: 10dvh;
  background:var(--color-white);
  justify-content: space-between;
  align-items: center;
  padding: 2px 6px;
}
.nav-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 6px;
}
.next-btn{
  background: var(--color-text-reservation);
  color:white;

}
.back-button{
  margin:20px;
  font-size: 16px;
  border: 1px solid grey

}
</style>