<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">確認</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-left">
          <div>本当に削除しますか？</div>
        </div>
        <div class="modal-footer" style="justify-content: center;">
          <button type="button" class="btn btn-outline-primary btn-round mr_medium btn-cancel-customer" data-dismiss="modal" @click="onClose">キャンセル</button>
          <button type="button" class="btn btn-delete-customer btn-round" @click="onDelete">削除</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import Http from "../../../shared/http"
export default {
  props: {
    customer: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async onDelete() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.delete(`${slug}/shop_manager/customer/${this.customer.id}`)
        .then((response) => {
          this.onClose();
          this.$emit('reload');
        })
        .catch((error) => {
          console.log(error)
        });
    },
  },
}
</script>

<style scoped>
  .modal-dialog {
    z-index: 1050;
  }
  .btn-delete-customer {
    background-color: #B96E71;
    padding: 11px 25px;
  }
  .btn-cancel-customer{
    color: var(--color-header-border) !important;
    &:hover {
      border-color: var(--color-header-border) !important;
      background-color: var(--color-header-border) !important;
      color: var(--color-white) !important;
    }
  }
</style>
