<template>
  <div class="modal fade show confirm_close" style="display: block;">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body text-left">
          <div>入力途中の内容があります。キャンセルしますか？</div>
        </div>
        <div class="modal-footer" style="justify-content: center;">
          <button type="button" class="btn btn-primary mr_medium" @click="onConfirm">はい</button>
          <button type="button" class="btn btn-outline-primary" @click="onClose">いいえ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onConfirm() {
      this.$emit('confirm');
    },
    onClose() {
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss" scoped>
  button:focus {
    outline: none;
  }
  .btn-fill:focus {
    outline: none !important;
  }
  .btn-delete-customer {
    padding: 11px 25px;
  }
  .modal-content {
    background-color: #fff;
  }
  .confirm_close .btn-outline-primary{
      border-color: var(--color-warning-light) !important;
      color: var(--color-warning-light);
  }
  .confirm_close .btn-outline-primary:hover{
      border-color: var(--color-warning-light) !important;
      color: var(--color-white);
      background-color: var(--color-warning-light) !important;
  }
</style>