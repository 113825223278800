import { render, staticRenderFns } from "./MemoModal.vue?vue&type=template&id=4935f588&scoped=true&"
import script from "./MemoModal.vue?vue&type=script&lang=js&"
export * from "./MemoModal.vue?vue&type=script&lang=js&"
import style0 from "./MemoModal.vue?vue&type=style&index=0&id=4935f588&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4935f588",
  null
  
)

export default component.exports